import React, { useState } from "react";

import { Row, Col, message } from "antd";
import { Switch } from "antd";
import Input from "../components/input";
import { PlacePicker } from "../components/place-picker";
import DateTimePicker from "../components/date-time-picker";
import PassengerInput from "../components/passenger-field";
import "./index.css";
import { FieldWrapper } from "../components/fieldWrapper";
import { RetweetOutlined } from "@ant-design/icons";
import Button from "components/UI/button";
import { useDetails } from "../utils";
import returnIcon from "../../../../src/icons/return-icon.png";
import moment from "moment";
import { textToUpperCase } from "../../../utils";
import dayjs from "dayjs";


function validateValue(values) {
    const { pickup_address, drop_address } = values;
    return {
        pickup_address:
            (!pickup_address && "Pick up location is required.") || "",
        drop_address:
            (!drop_address && "Drop off address is required.") || "",
        isError: !pickup_address || !drop_address,
    };
}
const RideInfo = ({ setPayload, labels, placeByRegionId, shortCode }) => {


    const { errors, values, handleValues, setFieldValue, handleError } =
        useDetails({}, { taxies: null });
    const scheduleDate = values.scheduled_date ? new Date(values.scheduled_date) : undefined;

    // const selectedTime = values?.scheduled_time?.$d;



    let returnDateTime = values.scheduled_date && values.scheduled_time ? values.scheduled_date + " " + values.scheduled_time : moment();
    let scheduleDateTime = values.scheduled_date + " " + values.scheduled_time
    let returnedDateTime = values.returned_date + " " + values.returned_time



    const onNext = () => {
        if (!(values.pickup_address && values.drop_address)) {
            message.error(
                "Both Pick up and Drop Off location fields are required."
            )
        } else if (
            values["isRetrun"] &&
            (new Date(returnedDateTime).getTime() - new Date(scheduleDateTime).getTime() < 30 * 60 * 1000)
        ) {
            message.error(
                "Please enter a return time 30 min greater than schedule time."
            );
        } else if (values.drop_address.name === values.pickup_address.name) {
            message.error(
                "Please enter a different Dropoff location from the Pickup location"
            );
        } else if (values.scheduled_date && !(values.scheduled_date && values.scheduled_time)) {
            message.error("Please Select Time.");
        } else if (values.scheduled_time && !(values.scheduled_date && values.scheduled_time)) {
            message.error("Please Select Date. ");
        } else if (values.isRetrun === true && values.returned_date && !(values.returned_date && values.returned_time)) {
            message.error("Please Select Return Time.");
        } else if (values.isRetrun === true && values.returned_time && !(values.returned_date && values.returned_time)) {
            message.error("Please Select Return Date.");
        } else if (values.isRetrun === true && !(values.returned_date && values.returned_time)) {
            message.error("Please Select Return Date and Time.");
        } else if ((values.scheduled_date && values.scheduled_time) && moment(scheduleDateTime).hours() < moment().hours() && moment(scheduleDateTime).minutes() < moment().minutes()) {
            message.error("Please Choose Current Time.");
        } else {
            const { isError, ...rest } = validateValue(values);
            handleError(rest);
            if (!isError) {

                const date = new Date();
                // const year = values?.scheduled_date.slice(0, 4);
                // const month = values?.scheduled_date.slice(5, 7);
                // const day = values?.scheduled_date.slice(8, 10);
                // const hour = values?.scheduled_time.slice(0, 2);
                // const min = values?.scheduled_time.slice(3, 5);

                const newValues = {
                    ...values,
                    current_time: date,
                    scheduled_time: (values.isScheduled && values.scheduled_time),
                    // schedule_time: `${values.scheduled_date} ${values.scheduled_time}`,
                    ride_time: new Date(`${values.scheduled_date} ${dayjs(values.scheduled_time).format("hh:mm:a")}`),
                    // return_date: returnDate,
                    // return_time: values.returned_time,
                }

                setPayload(newValues, 0);
            }
        }
    };

    const disabledHours = () => {
        let hours = [];
        if (scheduleDateTime) {
            if (moment(scheduleDateTime).date() == moment().date() && moment(scheduleDateTime).month() == moment().month()) {
                for (let i = 0; i < moment().hours(); i++) {
                    hours.push(i);
                }
            }
        }
        return hours;
    }

    const disabledMinutes = (scheduleHour) => {
        let minutes = [];
        if (scheduleHour == moment().hours() && moment(scheduleDateTime).month() == moment().month() && moment(scheduleDateTime).date() == moment().date()) {
            for (let i = 0; i < moment().minutes(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledHoursForReturnDate = () => {
        let hours = [];
        if (moment(returnedDateTime).date() == moment().date() && moment(returnedDateTime).month() == moment().month()) {
            for (let i = 0; i < moment().hours(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    const disabledMinutesForReturnDate = (scheduleHour) => {
        let minutes = [];
        if (scheduleHour == moment().hours() && moment(returnedDateTime).month() == moment().month() && moment(returnedDateTime).date() == moment().date()) {
            for (let i = 0; i < moment().minutes(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const currentDate =moment().format("YYYY/MM/DD");
    const selectedDate =values?.scheduled_date;
    const formatStr = 'YYYY/MM/DD';
    const current_Date = moment(currentDate, formatStr);
    const selected_Date = moment(selectedDate, formatStr);


    return (
      <div>
        <PlacePicker
          label={textToUpperCase(labels.pick_up_location)}
          onPlaceChange={handleValues("pickup_address")}
          onHouseNoChange={handleValues("pickup_address_home")}
          name="pickup_address"
          errors={errors}
          labels={labels}
          placeByRegionId={placeByRegionId}
          shortCode={shortCode}
        />
        <PlacePicker
          label={textToUpperCase(labels.drop_off_location)}
          onPlaceChange={handleValues("drop_address")}
          onHouseNoChange={handleValues("drop_address_home")}
          name="drop_address"
          errors={errors}
          labels={labels}
          placeByRegionId={placeByRegionId}
          shortCode={shortCode}
        />

        <Col lg={12} sm={24} xs={24}>
          <FieldWrapper className="selectScheudle">
            <Input
              className="scheduleInput"
              disabled
              // prefix={
              // <img
              //     src={returnIcon}
              //     style={{ width: 12, height: 12 }}
              //     alt=""
              // />
              // }
              bordered={false}
              value={labels.now}
              // value="Nu Ride"

              suffix={
                <Switch
                  value={values["isScheduled"]}
                  onChange={handleValues("isScheduled", !values["isScheduled"])}
                  // onClick={() =>
                  //     setFieldValue({
                  //         schedule_date: null,
                  //         // schedule_time: null,
                  //     })
                  // }
                />
              }
            />

            <span className="selected">{labels.schedule_ride_text}</span>
          </FieldWrapper>
        </Col>

        {values["isScheduled"] && (
          <DateTimePicker
            dateProps={{
              label: "Schedule Date",
              name: "date",
            }}
            timeProps={{
              label: labels.schedule_time,
              name: "time",
            }}
            handleChange={handleValues("scheduled_date")}
            handletimeChange={handleValues("scheduled_time")}
            labels={labels}
            disabledHours={disabledHours}
            disablePast={selected_Date.isAfter(current_Date) ? false : true}
            disabledMinutes={disabledMinutes}
          />
        )}

        <Row>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <PassengerInput
                label={labels}
                onChange={handleValues("passengers")}
              />
            </FieldWrapper>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <Input
                disabled
                prefix={
                  <img
                    src={returnIcon}
                    style={{ width: 12, height: 12 }}
                    alt=""
                  />
                }
                bordered={false}
                value={labels.return}
                suffix={
                  <Switch
                    value={values["isRetrun"]}
                    onChange={handleValues("isRetrun", !values["isRetrun"])}
                    onClick={() =>
                      setFieldValue({
                        return_date: null,
                      })
                    }
                  />
                }
              />
            </FieldWrapper>
          </Col>
        </Row>

        {values["isRetrun"] && (
          <DateTimePicker
            dateProps={{
              label: labels.return_date,
              name: "date",
            }}
            timeProps={{
              label: labels.return_time,
              name: "time",
            }}
            handleChange={handleValues("returned_date")}
            handletimeChange={handleValues("returned_time")}
            labels={labels}
            returnState={values.isRetrun}
            disabledHours={disabledHoursForReturnDate}
            disabledMinutes={disabledMinutesForReturnDate}
            returnDate={returnDateTime}
          />
        )}
        <div className="steps-action">
          <div className="mb-10">
            <Button
              title={labels.button_next || "Next"}
              fill={true}
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    );
};

export default RideInfo;