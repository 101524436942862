import React from "react";
import { MapFeature } from "components/BookingForm/components/map";
import { ProfileBookPage } from "components/ProfieBookingPage";
import { Header } from "components/BookingForm/components/header";
import { useLocation } from "react-router-dom";

export const ProfileBook = () => {
  const location = useLocation();
  return (
    <>
      <MapFeature>
        <Header />
        <ProfileBookPage
          firstName={location.state.firstName}
          fare={location.state.fare}
          lastName={location.state.lastName}
          phone={location.state.phone}
          pickup={location.state.pickup}
          dropoff={location.state.dropoff}
          driverImage={location.state.driverImage}
          rideId={location.state.rideId}
          customerPhoneNo={location.state.customerPhoneNo}
        />
      </MapFeature>
    </>
  );
};
