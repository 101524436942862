import React, { useEffect, useMemo } from "react";
import { Collapse } from "antd";
import { getTaxiTypesAPI } from "../../../services/api-services/region";
import * as FA from "react-icons/fa";
import Spinner from "../components/spinner";
import "./index.scss";
import CustomButton from "components/UI/button";
import { useDetails } from "../utils";
import taxiIcon from "../../../icons/taxi.png";
import peopleIcon from "../../../icons/people.png";

const { Panel } = Collapse;

function validateValue(values) {
    const { active } = values;
    return {
        active: (!active && "Please select a taxi") || "",
        isError: !active,
    };
}

const TaxiInfo = ({
    labels,
    setPayload,
    payload: { pickup_address, drop_address, current_time, scheduled_time, ride_time, ...rest },
    id = 1,
    regionId,
}) => {
    const { errors, values, setFieldValue, handleError } = useDetails();
    useEffect(() => {
        const loadTaxies = async () => {
            setFieldValue({
                loading: true,
            });
            const reqData = {
                source_lat: pickup_address?.latitude,
                source_lng: pickup_address?.longitude,
                destination_lat: drop_address?.latitude,
                destination_lng: drop_address?.longitude,
                // current_time: current_time,
                // scheduled_date: scheduled_date ?? "",
                // schedule_time: schedule_time ?? "",
                // return_date: return_date ?? "",
                // return_time: return_time ?? "",,

                ride_time: scheduled_time ? ride_time : current_time,

            };

            const resp = await getTaxiTypesAPI(reqData, regionId, rest.langCode);
            const { data, status } = resp;
            setFieldValue({
                // active: "0",
                selectedTaxi: status && data?.taxies?.length && data?.taxies[0],
                loading: false,
                taxies: (status && data?.taxies) || [],
                totalDistance: data?.totalDistance,
                currency: data?.currency
            });
        };

        if (pickup_address && drop_address) loadTaxies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickup_address, drop_address, current_time, scheduled_time, regionId, rest.langCode]);

    const onNext = () => {
        const { isError, ...rest } = validateValue(values);
        handleError(rest);
        if (!isError) setPayload(values, 1);
    };
    const onBack = () => {
        setPayload(values, 1, false);
    };

    const handleChange = (e) => {
        const { active = 0, taxies = [] } = values;
        if (e && e.length) {
            const [_, key = active] = e;
            setFieldValue({
                active: key || _,
                selectedTaxi: taxies[key || _],
            });
            handleError({ active: "" });
        }
    };
    const taxiList = useMemo(
        () =>
            values.taxies?.map((taxi, idx) => (
                <Panel
                    key={idx.toString()}
                    className="taxi-item"
                    header={
                        <TaxiHeader
                            taxi={taxi}
                            idx={idx}
                            active={values.active}
                        />
                    }
                >
                    <div className="desc">
                        {taxi.detail}
                        <FA.FaCheckCircle className="checked" />
                    </div>
                </Panel>
            )),
        [values.taxies, values.active]
    );

    return (
        <>
            {values.loading ? (
                <Spinner height="25vh" loading={values.loading} />
            ) : (
                <div className="collapse-margin">
                    <Collapse
                        className="taxi-list"
                        ghost
                        expandIconPosition="right"
                        style={{ background: "white" }}
                        // bordered={false}
                        activeKey={[values.active]}
                        onChange={handleChange}
                    >
                        {taxiList}
                    </Collapse>
                    {errors && errors["active"] && (
                        <div className="errorText">{errors["active"]}</div>
                    )}
                </div>
            )}
            <div className="steps-action">
                <CustomButton
                    title={labels.button_back || "BACK"}
                    fill={false}
                    onClick={onBack}
                />
                <div className="m-r-10">
                    <CustomButton
                        title={labels.button_next || "Next"}
                        fill={true}
                        onClick={onNext}
                        disable={!values.active ? true : false}
                    />
                </div>
            </div>
        </>
    );
};

const TaxiHeader = ({ taxi, active, idx }) => {
    return (
        <div className={`taxi-item-header ${active == idx ? "selected" : ""}`}>
            <span>
                <img src={taxiIcon} width="20" alt="" />
                &nbsp;&nbsp;
                <span>{taxi.name}</span>
            </span>
            <span className="flex-v-center">
                <img
                    src={peopleIcon}
                    style={{ /*width: 16,*/ height: 12, paddingRight: "4px" }}
                    alt=""
                />
                &nbsp;{taxi.seats}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>€{taxi.netFare.toFixed(1)}</span>
            </span>
        </div>
    );
};

export default TaxiInfo;