import React, { useEffect, useMemo, useState, useCallback } from "react";

import { useSearchParams, useParams } from "react-router-dom";
import { message } from "antd";
import RideInfo from "../RideInfo";
import TaxiInfo from "../TaxiInfo";
import UserInfo from "../UserInfo";
import Review from "../Review";
import Header from "./header";
import { Steps } from "antd";
import * as FA from "react-icons/fa";
import "./index.scss";
import Spinner from "../components/spinner";
import Logo from "components/logo";
import playStore from "icons/play-store.png"
import appStore from "icons/app-store.png"
// import DATA from "./data.json";
import { getRegionByIdAPI } from "../../../services/api-services/region";
const { Step } = Steps;

export default () => {
    const [step, setStep] = React.useState(0);
    const [reqestData, setReqestData] = React.useState({});
    const [labels, setLabels] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [regionId, setRegionId] = React.useState(1);
    const [placeByRegionId, setPlacesByRegionId] = useState();
    const [shortCode, setShortCode] = useState("");
    const [isOldUser, setIsOldUser] = useState(false);
    const [isUserFetching, setIsUserFetching] = useState(false);
    const [langCode, setLangCode] = useState();
    // const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams();
    const loadRegion = async () => {
        // const region_id = searchParams.get("region_id");
        setRegionId(id || 1);

        const resp = await getRegionByIdAPI(id || 27);

        const { status, data } = resp || {};
        setPlacesByRegionId(resp);
        if (status && data) {
            let labelField = data.labels_config?.labels || {};
            let headText = document.querySelector(":root");
            headText.style.setProperty("--i",`${labelField?.size}px`);
            setLangCode(data?.default_lang)
            setLabels(labelField);
            setError(false);
        } else {
            setError(true);
            message.error(resp?.error || "Something went wrong.");
        }
        // const { data } = DATA;
        // setLabels(data?.labels_config?.labels || {});
        setLoading(false);
    };
  
    const handleRequestData = (data, activeStep, isNext = true) => {
        setReqestData({ ...reqestData, ...data, langCode });
        if (isNext) setStep(activeStep + 1);
        else setStep(activeStep - 1);
    };

    useEffect(() => {
      let isMount = false

      if(isMount) return;
      loadRegion();
      
      return(()=>{
        isMount = true;
      })
    }, []);

    const userHandle = useCallback((val) => {
        setIsOldUser(val);
    }, []);

    const userFetching = useCallback((val) => {
        setIsUserFetching(val);
    });
    const steps = [
        {
            title: labels.select_location || "Select Location",
            content: (
                <RideInfo
                    setPayload={handleRequestData}
                    shortCode={setShortCode}
                    placeByRegionId={placeByRegionId}
                    labels={labels}
                />
            ),
        },
        {
            title: labels.select_taxi || "Select Taxi",
            content: (
                <TaxiInfo
                    labels={labels}
                    setPayload={handleRequestData}
                    payload={reqestData}
                    regionId={regionId}
                />
            ),
        },
        {
            title: labels.personal_details || "Personal Details",
            content: (
                <UserInfo
                    setPayload={handleRequestData}
                    shortCode={shortCode}
                    labels={labels}
                    userHandle={userHandle}
                    userFetching={userFetching}
                    payload={reqestData}
                />
            ),
        },
        {
            title: labels.review || "Ride Overview",
            content: (
                <Review
                    setPayload={handleRequestData}
                    details={reqestData}
                    labels={labels}
                    regionId={regionId}
                    isOldUser={isOldUser}
                    isUserFetching={isUserFetching}
                />
            ),
        },
    ];

    const stepCounterView = useMemo(
        () =>
            steps.map((item, i) => (
                <Step
                    icon={
                        step === i && (
                            <FA.FaCarSide
                                style={{ color: "#76B138", fontSize: 32 }}
                            />
                        )
                    }
                    key={item.title}
                    title={item.title}
                />
            )),
        [step, labels]
    );

    const formView = useMemo(
        () =>
            steps.map((item, index) => (
                <div
                    key={index}
                    className={step === index ? "steps-content" : "step-hide"}
                >
                    {item.content}
                </div>
            )),
        [step, labels, isOldUser, isUserFetching]
    );

    if (error) return null;

    return (
      <>
        {/* <Logo onClick={() => window.location.reload()} /> */}
        {labels.header_text && <Header
          heading={labels.header_text}
          class={"header-text-style"}
        />}
        <div className="booking-form-root">
          <div id="stepper" className={"normalView"}>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <>
                <Header
                  heading={labels.book_your_taxi || "Book Your Taxi"}
                  class={"header-styles"}
                />
                <div className="form-root">
                  <Steps
                    current={step}
                    size="small"
                    direction="horizontal"
                    labelPlacement="vertical"
                    className="zapp-steps"
                  >
                    {stepCounterView}
                  </Steps>
                  <div className="selected-header">{steps[step].title}</div>
                  <div className="steps-root">{formView}</div>
                </div>
              </>
            )}
          </div>
        </div>
        {!loading && (
          <div className="steps-action">
            <div className=" link-section mb-10">
              <a
                href="https://play.google.com/store/apps/details?id=com.ellocent.zapp"
                target="_blank"
              >
                <div className="icon-size">
                  <img src={playStore} />
                </div>
              </a>
              <a
                href="https://apps.apple.com/in/app/zapp-taxi-booking-app/id1636831011"
                target="_blank"
              >
                <div className="icon-size">
                  <img src={appStore} />
                </div>
              </a>
            </div>
          </div>
        )}
      </>
    );
};
