export const errorHandler = async (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.status !== 403 &&
    error.response.status !== 401
  ) {
    return {
      status: false,
      error: getError(error.response.data?.error) || "Something went wrong",
    };
  } else if (error && error.response && error.response.status === 401) {
    //When access token expires
  //  logoutHandler();
    return { status: false, error: "Token expired!" };
  } else if (error && error.response && error.response.status === 403) {
    //Either Invalid Token or Access and refresh token expire.
  //  logoutHandler();
    return { status: false, error: "Token expired!" };
  } else {
    return { status: false, error: "Something went wrong!" };
  }
};

const getError = (errors) => {
  try {
    if (typeof errors === "string") return errors;
    for (const [key, value] of Object.entries(errors)) {
      return value[0];
    }
  } catch (error) {
    return undefined;
  }
};
