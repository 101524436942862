import React, { useState } from 'react'
import { DateTimePickerTabs, LocalizationProvider, MobileTimePicker, TimeClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DatePicker from '../date-picker'
// import TimePicker from '../time-picker'
import { FieldWrapper } from '../fieldWrapper'
import { Row, Col } from 'antd'
import moment from 'moment'
import { green, grey, lightGreen, orange, red } from '@mui/material/colors';
import dayjs from 'dayjs';
import "./style.css";
import { ThemeProvider, createTheme } from '@mui/material';
import { Clock } from '@mui/x-date-pickers/TimeClock/Clock';
import TimePicker from '../time-picker';

import IconTime from '../../../../icons/time-gray.png';


const theme = createTheme({
    palette: {
        primary: {
            main: lightGreen[600]
        },
        text: {
            primary: grey[500]
        },
    },
    shape: {
        borderRadius: 8,

    },
    typography: {
        fontSize: 11,
        fontFamily: "revert",

    },
});
// import dayjs from "dayjs";

const preZero = (n) => (n > 9 ? n : "0" + n);

const DateTimePicker = ({ dateProps, timeProps, handleChange, labels, disabledHours,disablePast, disabledMinutes, returnState, returnDate, handletimeChange }) => {
    const [time, setTime] = useState();


    const formatAndPassTime = (val) => {
        if (!val) return;
        const formattedTime = dayjs(val).format('hh:mm:a');
        console.info(val, formattedTime);
        handletimeChange(formattedTime);
        setTime(val);
    }




    // console.log("tttt", time);

    return (
        <Row>
            <Col lg={12} sm={12} xs={24} >
                <FieldWrapper>
                    <DatePicker {...dateProps} onChange={handleChange} labels={labels} />
                </FieldWrapper>
            </Col>
            <Col lg={12} sm={12} xs={24} >
                {/* <ThemeProvider theme={theme}   >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker

                            className='muiTimePicker loldfjsdkfjsdfkljsdfsklfjsdfkl'
                            sx={{ height: "30px" }}
                            {...timeProps}
                            // label={"Schedule Time"}
                            clearable={true}
                            disabledHours={disabledHours}
                            returnDate={returnDate}
                            disabledMinutes={disabledMinutes}
                            value={time}
                            onChange={formatAndPassTime}
                            returnState={returnState}
                            // onClose={(val) => console.log("kdkdkdkd", val)}
                            // defaultValue={dayjs('2022-04-17T15:30')}
                            localeText={dayjs}
                            ampm={false}



                            slotProps={{
                                textField: {
                                    InputProps: {
                                        startAdornment: <img src={IconTime} alt='' style={{ marginLeft: "-5px", marginRight: '5px', position: "absolute", top: "10px", height: 15, width: 15 }} />,
                                        endAdornment: <EndAdornment onClick={formatAndPassTime} handletimeChange={handletimeChange} />,
                                    },
                                },
                                actionBar: {
                                    actions: ['clear', 'accept'],
                                },
                                field: {
                                    className: "child"
                                },
                            }}


                        // labels={labels}
                        />
                    </LocalizationProvider>
                </ThemeProvider> */}

                <FieldWrapper>
                    <TimePicker  {...timeProps} disablePast={disablePast} disabledHours={disabledHours} returnDate={returnDate} disabledMinutes={disabledMinutes} onChange={handletimeChange} returnState={returnState} labels={labels} />
                </FieldWrapper>
            </Col>
        </Row>
    )
}

export default DateTimePicker;


const EndAdornment = ({ onClick }) => {
    const clickHandler = (e) => {
        e.stopPropagation();
        onClick(dayjs());
    }
    return <button className='nowBtn' onClick={clickHandler}>Now</button>
}