import React, { useState, useCallback } from "react";
import Input from "../input";
import { List, Typography, Row, Col } from "antd";
import { FieldWrapper } from "../fieldWrapper";
import "./style.css";
import { parseAddress } from "./utils";
import locationIcon from "../../../../icons/location-pin.png";
import homeIcon from "../../../../icons/home-gray.png";
import InfiniteScroll from "react-infinite-scroll-component";
// const MAPTOKEN = process.env.REACT_APP_MAPTOKEN;
// const BASEURL = process.env.REACT_APP_MAPPLACES;
const MAPTOKEN =
  "pk.eyJ1IjoiYW5hbmRzaGFtIiwiYSI6ImNrb3RnYnI2dTBhejAyeG13NnFocXU0dXMifQ.z3oL4ykm-whw_IkslVR13g";

const BASEURL = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const ACCESS_TOKEN = MAPTOKEN,
  BASE_URL = BASEURL;
var preventApis = false;
var previousvalue = "";

export const PlacePicker = React.forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState("");
  const [features, setFeatures] = useState([]);
  const [feature, setFeature] = useState();
  const [hNo, setHNo] = useState();
  const [, setLoading] = useState(false);
  // const inputRef = useRef();
  let url;

  const { onHouseNoChange, onPlaceChange, ...rest } = props;

  const searchRegion = (query) => {
    if (preventApis) return (preventApis = false);
    if (!query) return setFeatures([]);
    const bbox = [2, 47, 17, 55];
    const radius = bbox ? "&bbox=" + bbox : "";
    // const url = `${BASE_URL}${query}.json?proximity=${props.placeByRegionId.data.longitude},${props.placeByRegionId.data.latitude}&access_token=${ACCESS_TOKEN}&limit=4`;
    if (process.env.NODE_ENV == "production") {
      url =
        `${BASE_URL}${query}.json?&access_token=${ACCESS_TOKEN}&limit=4` +
        radius;
    } else {
      url = `${BASE_URL}${query}.json?&access_token=${ACCESS_TOKEN}&limit=4`;
    }
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setFeatures(res?.features || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onInputChange = useCallback(
    (evt) => {
      setInputValue(evt.target.value);
      searchRegion(evt.target.value);
    },
    [inputValue]
  );

  const onChnageH = (evt) => {
    setHNo(evt.target.value);
    onHouseNoChange(evt.target.value);
  };

  const onSelect = useCallback(
    (feature) => () => {
      let contextLength = feature.context;
      let shortCountryCode = feature.context
        ? feature.context[contextLength.length - 1].short_code
        : feature.properties.short_code;
      preventApis = true;
      setInputValue(feature.place_name);
      setFeatures([]);
      setFeature(feature);
      previousvalue = feature.place_name;
      onPlaceChange(parseAddress(feature));
      props.shortCode(shortCountryCode);
    },
    [feature]
  );
  const handleBlur = () => {
    if (!inputValue) {
      preventApis = true;
      setFeatures([]);
      setInputValue(previousvalue);
    }
  };
  const handleFocus = () => {
    previousvalue = inputValue;
    setInputValue("");
  };

  return (
    <div id="location-picker">
      <Row>
        <Col lg={18} sm={18} xs={16}>
          <FieldWrapper>
            <Input
              {...rest}
              prefix={
                <img
                  src={locationIcon}
                  style={{ width: 10, height: 15 }}
                  alt=""
                />
              }
              onChange={onInputChange}
              value={inputValue}
              ref={ref}
              onBlur={handleBlur}
              onFocus={handleFocus}
              autoComplete="none"
            />
            {props.errors && props.errors[props.name] && (
              <div className="errorText">{props.errors[props.name]}</div>
            )}
          </FieldWrapper>
        </Col>
        <Col lg={6} sm={6} xs={8}>
          <FieldWrapper>
            <Input
              name={props.name}
              label={props.labels.house_no || "No."}
              prefix={
                <img src={homeIcon} style={{ width: 12, height: 13 }} alt="" />
              }
              value={hNo}
              onChange={onChnageH}
              autoComplete="none"
            />
          </FieldWrapper>
        </Col>
      </Row>
      {Boolean(features.length) && (
        <InfiniteScroll
          height={"auto"}
          dataLength={features.length}
          style={{ overflow: "auto" }}
        >
          <List
            className="listView"
            bordered
            dataSource={features}
            renderItem={(feature) => (
              <List.Item key={feature.id} onClick={onSelect(feature)}>
                <Typography.Text>{feature.place_name}</Typography.Text>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      )}
    </div>
  );
});
