import styled from "styled-components";

const Container = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: 100vh;
  // flex-direction: column;
  // width:24%
  // min-width: 53em;
  max-width: 33rem;
  font-family: "Poppins", sans-serif;
  margin-inline: auto;
  margin-top:2rem
  
  // @media (max-width:320px) {
  //   min-width: 0em;
  // }
`;

const ProfileContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  elevation: 5;
  // height:20%;
  max-height: 600px;
  padding: 15px;
  box-shadow: 0px 5px 14px #00000029;
  margin-bottom: 10px;
`;

const VerticalLine = styled.div`
  height: 16px;
  border-left: 1px solid black;
  margin-left: 5px;
  margin-right: 5px;
`;

const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content:space-between;
`;

const ImageWrap = styled.div`
  flex-direction: column;
  margin-bottom: 22px;
  // margin-left: 4px;
`;

const DriverImage = styled.img`
  border-radius: 200px;
  height: 55px;
  width: 55px;
`;

const NameContainer = styled.div`
  flex-direction: column;
  margin-left:10px;
`;
const NameWrap = styled.div`
  flex-direction: row;
  display: flex;
`;
const Rating = styled.div`
  margin-top: 1px;
`;
const StarIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 3px;
  margin-top: 3px;
`;
const PhoneWrap = styled.div`
  flex-direction: row;
  display: flex;
`;
const PhoneIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 3px;
`;
const CarWrap = styled.div`
  flex-direction: row;
  display: flex;
`;

const VerticalLineWrapper = styled.div`
  margin-top: 3px;
`;

const SedanIcon = styled.img`
  width: 100px;
  height: 70px;
  margin-right: 6px;
`;

const ScheduledReturnRideContainer = styled.div`
  
  background-color: white;
  border-radius: 12px;
  elevation: 5;
  // height: 75%;
  padding: 20px;
  box-shadow: 0px 5px 14px #00000029;
  margin-bottom: 5%;

`;

const ScheduledReturnRideWrap = styled.div`
  flex-direction: row;
  display: flex;
`;

const ScheduledRideCardContainer = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
`;

const ScheduledRideCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 12px;
  elevation: 5;
  // min-width: 23em;
  width:auto;
  // max-height: 23vh;
  padding: 12px;
  box-shadow: 0px 5px 14px #00000029;
  margin-bottom: 1%;
  // margin-right: 5px;
  flex-direction: column;

  // @media (max-width:320px) {
  //   min-width:0em
  // }
`;
const ReturnRideCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 12px;
  elevation: 5;
  // min-width: 23em;
  // max-height: 23vh;
  width:auto;
  padding: 12px;
  box-shadow: 0px 5px 14px #00000029;
  margin-bottom: 1%;
  margin-top: 10px;
  // margin-right: 5px;
  flex-direction: column;
`;

const PickUpDropOffContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PickUpDropOffWrap = styled.div`
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
`;

const PickUpIconWrap = styled.div``;

const Connector = styled.div`
  height: 30px;
  border-left: 1px solid #76b138;
  margin-left: 5px;
`;

const PickUpDropOffTextWrap = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 8px;
`;

const PickUpIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
`;

const DropOffIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const PickUpText = styled.div`
  margin-top: 4px;
`;

const PickUpLocation = styled.div`
  margin-top: 4px;
`;

const DropOffText = styled.div`
  margin-top: 6px;
`;

const DropOffLocation = styled.div`
  margin-top: 4px;
`;

const TotalFareWrap = styled.div`
  display: flex;
  background-color: #f2f2f2;
  align-items: center;
  border-radius: 5px;
  elevation: 5;
  width: 98%;
  padding: 8px;
  justify-content: space-between;
  flex-direction: row;
  border-width: 1 px;
  margin-top: 20px;
`;

const TotalFee = styled.div`
  margin-left: 1px;
`;

const Fee = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;

const Button = styled.div`
  width: 145px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #76b138;
  margin-top: 25px;
  // margin-bottom: 15px;
  font-weight: bold;

  @media (max-width: 320px) {
    width: auto;
  }
`;

const MapButton = styled.button`
  width: 120px;
  height: 4vh;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 22%;
  color: #76b138;
  border: none;
  font-family: "Poppins", sans-serif;
`;

const MapWrap = styled.div`
  margin-left: 12px;
`;

const ScheduledReturnWrap = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;
const ScheduledReturnText = styled.div`
  font-weight: bold;
`;

const NameText = styled.div`
  font-weight: bold;
  font-size: 17px;
`;

const DateText = styled.div`
  margin-left: 24px;
`;

const ReturnDateText = styled.div`
  margin-left: 48px;
`;

const SubContainer = styled.div`
  margin: 1rem;
  width: auto;

  @media (max-width:320px) {
    width: auto;
  }
`
export {
  Container,
  ProfileContainer,
  VerticalLine,
  ProfileWrap,
  ImageWrap,
  DriverImage,
  NameContainer,
  NameWrap,
  StarIcon,
  PhoneWrap,
  PhoneIcon,
  CarWrap,
  VerticalLineWrapper,
  SedanIcon,
  ScheduledReturnRideContainer,
  ScheduledReturnRideWrap,
  ScheduledRideCardContainer,
  ScheduledRideCard,
  PickUpDropOffContainer,
  PickUpDropOffWrap,
  PickUpIconWrap,
  Connector,
  PickUpDropOffTextWrap,
  PickUpIcon,
  DropOffIcon,
  PickUpText,
  PickUpLocation,
  DropOffText,
  DropOffLocation,
  ReturnRideCard,
  TotalFareWrap,
  TotalFee,
  Fee,
  Button,
  MapButton,
  Rating,
  MapWrap,
  ScheduledReturnWrap,
  ScheduledReturnText,
  DateText,
  NameText,
  ReturnDateText,
  SubContainer,
};
