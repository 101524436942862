import React, { useState } from "react";

const useDetails = (initialValues = {}, defaultOther = {}) => {
  const [errors, setError] = useState({});
  const [values, setValues] = useState(initialValues);
  const [touched, setTouch] = useState({});
  const handleTouch = (name) => () => {
    setTouch((preState) => ({ ...preState, [name]: true }));
  };
  const setFieldValue = (fieldValue, error) => {
    setValues((preState) => ({
      ...preState,
      ...defaultOther,
      ...fieldValue,
    }));
    if (error) handleError(error);
  };
  const handleTouchAll = (allData) => () => {
    setTouch(allData);
  };
  const handleError = (error) => {
    setError((preState) => ({
      ...preState,
      ...error,
    }));
  };
  const handleValues = (name, defaultValue) => (value) => {
    setValues((preState) => {
      return {
        ...preState,
        ...defaultOther,
        [name]: value || defaultValue,
      };
    });
    handleError({ [name]: "" });
  };
  return {
    errors,
    values,
    touched,
    handleTouch,
    handleTouchAll,
    handleValues,
    handleError,
    setFieldValue,
  };
};

export { useDetails };
