import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 0;
`;

const ImageContainer = styled.div`
  display: inline-block;
  width: 100%;
  background-color: white;
  padding: 10px;
`;

const Image = styled.img`
height: 25px;
width: 70px;
margin-top: 10px;
margin-bottom: 10px;
margin-left: 80px;
`;

export { Container,ImageContainer,Image};
