import axios from "axios";
import { errorHandler } from "./error-handler";

const BASEURL = process.env.REACT_APP_BASEURL;

const getHeader = async (status, api, lang) => {
  const header = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Accept-Language": lang,
  };

  if (status) {
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9yaWRlLWJvb2tpbmctYXBpLmhlcm9rdWFwcC5jb21cL2FwaVwvYWRtaW5cL2xvZ2luIiwiaWF0IjoxNjQzNzAwMzQ3LCJleHAiOjE2NDM3MDM5NDcsIm5iZiI6MTY0MzcwMDM0NywianRpIjoiamdoRHM0TkFBU0xoUXdQUCIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsIm5hbWUiOm51bGx9.-LAvhMkbeEvptzM-KCZXyFyp_lc8jm3nLlewy6qr5vQ"
    //await getToken();
    if (token) header["Authorization"] = `Bearer ${token}`;
  }

  return header;
};

const Request = ({ method, api, data, token, lang }) => {
  return new Promise(async (resolve, reject) => {
    const headers = await getHeader(token, api, lang);
    const url = `${BASEURL}${api}`;
    try {
      axios({ method, url, data, headers })
        .then((response) => {
          // if (response && response.data && "status" in response.data)
          resolve(response.data);
          // resolve({ status: true, data: response.data });
        })
        .catch((error) => {
          resolve(errorHandler(error));
        });
    } catch (error) {

      resolve(errorHandler(error));
    }
  });
};

export default Request;
