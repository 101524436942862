import React, { useState, useEffect } from "react";
import ReactMapboxGl, { ZoomControl, Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import SocketSync from "../../../../socket";
import pinIcon from "../../../../icons/pin.png";

let socketInstance = SocketSync.getInstance();
const MAPTOKEN = process.env.REACT_APP_MAPTOKEN;

const Map = ReactMapboxGl({
  accessToken:
    MAPTOKEN,
});

export const MapFeature = ({ children }) => {
  const [coords, setCoords] = useState([0, 0]);

  useEffect(() => {
    let socket = socketInstance.getActiveSocket();
    socket.on("driver_location_update", (arg) => {
      const { latitude, longitude } = arg.rideDetail?.driver || {};
      const newCoords = [longitude, latitude];
      setCoords(newCoords);
    });
  }, [coords]);

  return (
    <Map
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: "100vh",
        width: "100vw",
      }}
      center={coords}
    >
      {children}

      <Marker coordinates={coords} anchor="center">
        <img src={pinIcon} style={{ height: 40, width: 40 }} alt="" />
      </Marker>

      <div>
        <ZoomControl
          style={{
            backgroundColor: "#76b138",
            color: "white",
          }}
          position="bottom-right"
        />
      </div>
    </Map>
  );
};
