import React from "react";
import {
  Container,
  StatusContainer,
  CancelIconWrap,
  CancelIcon,
  SuccessIconWrap,
  SuccessIcon,
  SuccessText,
  CongratulationsText,
  TrackText,
  DownloadText,
  StoreIconWrap,
  StoreIcons,
} from "./styles";
import cancelIcon from "../../icons/cancel.png";
import successIcon from "../../icons/success.png";
import storeIcons from "../../icons/stores.png";
import { Header } from "components/BookingForm/components/header";

export const PaymentStatus = () => {
  return (
    <>
      <Header />
      <Container>
        <StatusContainer>
          <CancelIconWrap>
            <CancelIcon src={cancelIcon} alt="" />
          </CancelIconWrap>
          <SuccessIconWrap>
            <SuccessIcon src={successIcon} alt="" />
          </SuccessIconWrap>
          <SuccessText>Payment Successful!</SuccessText>
          <CongratulationsText>
            Congratulations! It has been successful to process your payment.hope
            you see you again soon.
          </CongratulationsText>

          <TrackText>
            To track your previous record{" "}
            <DownloadText>download the app</DownloadText>
          </TrackText>

          <StoreIconWrap>
            <StoreIcons src={storeIcons} alt="" />
          </StoreIconWrap>
        </StatusContainer>
      </Container>
    </>
  );
};
