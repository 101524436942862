import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const WaitContainer = styled.div`
  display: flex;
  background-color: white;
  margin: 30px;
  border-radius: 12px;
  elevation: 5;
  height: 60%;
  padding: 20px;
  box-shadow: 0px 5px 14px #00000029;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 1 45em;
`;

const CircleContainer = styled.div`
  background: white;
  border-radius: 50%;
  box-shadow: 0px 5px 14px #00000029;
  position: relative;
  display: inline-block;
  width: 140px;
  height: 140px;
  margin-top: 25px;
`;
const OneHundredText = styled.div`
  font-weight: bold;
  font-size: 50px;
  color: #76b138;
  text-align: center;
  margin-top:15px;
`;

const SecondsText = styled.div`
  color: #76b138;
  text-align: center;
  font-family: Poppins;
`;

const WaitText = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  font-family: Poppins;
`;

const ContactAdminOrTryAgainText = styled.div`
  margin:25px 30px;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  font-family: Poppins;
`;
const AdditionalText = styled.div`
  font-weight: semi-bold;
  text-align: center;
  width: 80%;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: Poppins;
`;

const ButtonWrapper = styled.div`
  font-weight: bold;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
`;

const Button = styled.div`
  // width: 150px;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #76b138;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #fff;
`;

const TryAgainButton = styled.div`
  width: 200px;
  height: 35px;
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid #76b138;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #76b138;
  font-size: 20px;
  font-weight: bold;
  margin:10px;
  // border: 1px solid #fff;
`;
const ContactButton = styled.div`
  width: 200px;
  height: 43px;
  background-color: #76b138;
  border-radius: 25px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  // border: 1px solid #fff;
  margin:10px;
  
`;

const Close = styled.div`
  width: 3rem;
  height: 3rem;
  text-align: center;
  border-radius: 50%;
  margin-left: auto;
  font-size:2rem;
  cursor:pointer;
`
const TimerUp = styled.div`
  width:3.5rem;
  height:3.5rem;
  border-radius:50%;
  box-shadow: 0px 5px 14px #00000029;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:1.4rem;
  font-weith:bold;
  color: #76b138;
  margin:.7rem;
  margin-left:auto
`
export {
  Container,
  WaitContainer,
  CircleContainer,
  OneHundredText,
  SecondsText,
  WaitText,
  AdditionalText,
  ButtonWrapper,
  Button,
  ContactButton,
  TryAgainButton,
  ContactAdminOrTryAgainText,
  Close,
  TimerUp
};
