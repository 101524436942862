const getKeys = (labels) => ({
  first_name: { name: labels.customer_firstname || "First name" },
  last_name: { name: labels.customer_lastname || "Last name" },
  email: { name: labels.customer_email || "Email" },
  phone_no: {
    name: "Phone number",
    min: 10,
    minError: "Please Provide a valid phone number",
  },
});

function validateValues(values, keys) {
  const errors = { isError: false };
  for (const key of Object.keys(keys)) {
    const value = values[key] || "";
    const min = keys[key]?.min || 0;
    if (min && value.length <= min) {
      errors[key] =
        keys[key]?.minError || `${keys[key]?.name} should be minimum  ${min}`;
    } else {
      errors[key] = (!values[key] && `${keys[key]?.name} is required`) || "";
    }
    errors["isError"] = errors.isError || !values[key];
  }
  return errors;
}

export { getKeys, validateValues };
