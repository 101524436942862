import React, { useState, useEffect, memo, useCallback } from "react";
import { Header } from "../header";
import {
  Container,
  WaitContainer,
  CircleContainer,
  OneHundredText,
  SecondsText,
  WaitText,
  AdditionalText,
  ButtonWrapper,
  Button,
  TryAgainButton,
  ContactButton,
  ContactAdminOrTryAgainText,
  Close,
  TimerUp,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { retryRideAPI, cancelRideAPI } from "services/api-services/ride";
import { Modal, message, Spin, Tooltip } from "antd";
import SocketSync from "socket";

let socket = SocketSync.getInstance();

const alertUser = (event) => {
  event.preventDefault();
  return (event.returnValue = "Are you sure you want to exit?");
};

export const WaitCard = memo(() => {
  const location = useLocation();
  const navigation = useNavigate();
  let isScheduled = location.state?.isScheduled;
  let phoneNo = location.state?.phoneNo;
  let labels = location.state?.labels;
  // const text = "Please try again otherwise ride will be cancelled after 30 seconds."
  const [counter, setCounter] = useState(isScheduled ? 600 : 120);
  const [showButton, setShowButton] = useState(false);
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [timeOutCall, setTimeOutCall] = useState();
  // const [countDown, setCountDown] = useState(30);
  // const [countDownCall, setCountDownCall] = useState();
  useEffect(() => {
    if (counter === 0) {
      setHide(false);
      setShowButton(true);
      setCounter(null);
    }

    if (counter === 0 && count === 1) {
      let timeOut = setTimeout(() => {
        cancelRide();
        location.state.regionid
          ? navigation(`/region/${location.state.regionid}`)
          : navigation("/");
      }, 30000);
      setTimeOutCall(timeOut);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const reset = () => {
    setHide(true);
    setShowButton(false);
    setCounter(120);
    reBookRide();
    setCount(count + 1);
    clearTimeout(timeOutCall);
  };

  const reBookRide = async () => {
    const result = await retryRideAPI(location.state.id, location.state.lang, {
      customer_id: location.state.customerId,
    });
    return result;
  };

  const cancelRide = async () => {
    setLoading(true);
    setCount(count - 1);
    const result = await cancelRideAPI(location.state.id, location.state.lang, {
      status: "CANCELLED",
      phone_no: phoneNo,
    });
    if (result.status) {
      message.success(result.data && "Ride Cancelled Successfully");
      location.state.regionid
        ? navigation(`/region/${location.state.regionid}`)
        : navigation("/");
      setOpen(false);
      setCounter(isScheduled ? 600 : 120);
      setLoading(false);
    } else {
      message.error(result.error);
    }
  };

  const contactMail = () => {
    window.location.href = "tel:(+31) 402-405999";
  };

  const onCloseModel = () => {
    setOpen(false);
    clearTimeout(timeOutCall);
  };

  const openModel = () => {
    setOpen(true);
  };
  return (
    <>
      <Modal
        title={labels?.cancel_ride_head ?? "Cancel Ride"}
        visible={open}
        onOk={cancelRide}
        onCancel={onCloseModel}
        okText={labels?.confirm_btn ?? "Confirm"}
        cancelText={labels?.cancel_ride_btn ?? "Cancel"}
        maskClosable={false}
        className={"cancel-ride"}
        closable={false}
        okButtonProps={{ disabled: loading ? true : false }}
        cancelButtonProps={{ disabled: loading ? true : false }}
        centered
      >
        {loading ? (
          <div className="wait-text">
            <Spin />
            <div>
              {labels?.cancel_ride_text ?? "We are cancelling ride for you."}
            </div>
          </div>
        ) : (
          <p>
            {labels?.cancel_alert_text ??
              "Are you sure you want to cancel ride?"}
          </p>
        )}
      </Modal>
      {/* <Header /> */}
      {/* {showButton == true && count == 1 && <Tooltip placement="left" title={text}><TimerUp>{countDown}</TimerUp></Tooltip> } */}
      <Container>
        <WaitContainer>
          <Close
            onClick={() => {
              clearTimeout(timeOutCall);
              location.state.regionid
                ? navigation(`/region/${location.state.regionid}`)
                : navigation("/");
            }}
          >
            X
          </Close>

          {showButton ? (
            isScheduled == true ? (
              <>
                <WaitText>
                  {labels?.try_again_head ?? "Sorry for the trouble"}
                </WaitText>
                <AdditionalText>
                  {labels?.try_again_text ??
                    `At the moment it seems that all our drivers are busy, please try again or call the switchboard +3140 240 5999, apologies for the inconvenience`}
                </AdditionalText>
              </>
            ) : showButton == true && count == 1 ? (
              <ButtonWrapper>
                <Button onClick={reset}>
                  {labels?.try_again_btn ?? "Try Again"}
                </Button>
              </ButtonWrapper>
            ) : (
              <>
                <ContactAdminOrTryAgainText>
                  {labels?.driver_not_found ??
                    "Sorry for the trouble, currently there are no drivers availble at the moment. please try again or contact admin."}
                </ContactAdminOrTryAgainText>
              </>
            )
          ) : (
            <CircleContainer>
              <div>
                <OneHundredText>{counter}</OneHundredText>
                <SecondsText>{labels?.timer_seconds ?? "Seconds"}</SecondsText>
              </div>
            </CircleContainer>
          )}
          {showButton == true && count == 2 ? (
            <>
              <ButtonWrapper>
                <TryAgainButton
                  onClick={() =>
                    location.state.regionid
                      ? navigation(`/region/${location.state.regionid}`)
                      : navigation("/")
                  }
                >
                  {labels?.button_back ?? "Go Back"}
                </TryAgainButton>
                <ContactButton onClick={contactMail}>
                  {labels?.contact_us_btn ?? "Contact Us"}
                </ContactButton>
              </ButtonWrapper>
            </>
          ) : (
            <>
              {showButton == true && count == 1 ? (
                isScheduled == true ? (
                  <>
                    <ButtonWrapper>
                      <TryAgainButton
                        onClick={() => {
                          cancelRide();
                          location.state.regionid
                            ? navigation(`/region/${location.state.regionid}`)
                            : navigation("/");
                        }}
                      >
                        {labels?.button_back ?? "Go Back"}
                      </TryAgainButton>
                      <ContactButton onClick={contactMail}>
                        {labels?.contact_us_btn ?? "Contact Us"}
                      </ContactButton>
                    </ButtonWrapper>
                  </>
                ) : (
                  <>
                    <WaitText>
                      {labels?.try_again_head ?? "Sorry for the trouble"}
                    </WaitText>
                    <AdditionalText>
                      {labels?.try_again_text ??
                        `At the moment it seems that all our drivers are busy, please try again or call the switchboard +3140 240 5999, apologies for the inconvenience`}
                    </AdditionalText>
                  </>
                )
              ) : (
                <>
                  <WaitText>
                    {labels?.wait_a_moment ?? "Wait A Moment!"}
                  </WaitText>
                  <AdditionalText>
                    {labels?.wait_msg ??
                      "Your booking will confirm shortly, please be patient."}
                  </AdditionalText>
                </>
              )}

              {hide == true && (
                <Button onClick={openModel}>
                  {labels?.button_cancel ?? "Cancel Ride Search"}
                </Button>
              )}
            </>
          )}
        </WaitContainer>
      </Container>
    </>
  );
});
