var SocketInstance;

export default class SocketSync {
    activeSocket = null;

    static getInstance() {
        if (!SocketInstance) {
            SocketInstance = new SocketSync();
        }

        return SocketInstance;
    }

    setActiveSocket(val) {
        SocketInstance.activeSocket = val;
    }

    getActiveSocket() {
        return SocketInstance.activeSocket;
    }
}
