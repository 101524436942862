import React from "react";
import { Input } from "antd";
import FloatLabel from "./label";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";

export default function InputCustom(props) {
  const InputComponent = props.type === "textarea" ? Input.TextArea : Input;
  const { inputRef, ...rest } = props
  return props.type === "phone" ? (
    <PhoneInput {...props} inputStyle={{ fontSize: "12px" }} />
  ) : (
    <div id={props.id ? props.id : "zapp-input"}>
      <FloatLabel {...props}>
        <InputComponent ref={inputRef} {...rest} />
      </FloatLabel>
    </div>
  );
};
