import React, { useRef, useState } from "react";
import Input from "../input";
import { Modal } from "antd";
import Calendar from "./calendar";
import "./index.css";
import moment from "moment";
import dateIcon from "../../../../icons/calendar-gray.png";

export default function DatePicker(props) {
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState();
  const ref = useRef();

  const onCancelRequest = () => {
    setVisible(false);
    setValue('')
    props.onChange("");
  };

  const onSelect = (date) => {
    const formatDate = moment(date).format("D MMMM YYYY");
    setValue(formatDate);
    setVisible(false);
    props.onChange(date);
    setTimeout(() => {
      ref.current.blur();
    }, 200);
  };


  const selectDate = (() => {
    setValue(moment().format("D MMMM YYYY"))
    props.onChange(moment().format("D MMMM YYYY"))
  })

  // const suffix =
  //   value === "" ? (
  //     <div
  //       onClick={selectDate}
  //       className="now-text"
  //     >
  //       {props.labels?.now}
  //     </div>
  //   ) : null;

  return (
    <div id="zapp-date-picker">
      <Input
        prefix={<img src={dateIcon} style={{ height: 15, width: 15 }} alt="" />}
        inputRef={ref}
        onClick={() => setVisible(true)}
        value={value}
        label={props.labels?.date || "Date"}
        // suffix={suffix}
        readOnly={true}
        {...props}
      />
      <Modal
        centered
        mask
        visible={visible}
        closable={false}
        footer={false}
        width={350}
        // style={{maxWidth: "26em"}}
        className="zapp-date-picker"
        contentStyle={{ borderRadius: 20 }}
      >
        {visible ? (
          <Calendar onCancelRequest={onCancelRequest} onSave={onSelect} />
        ) : null}
      </Modal>
    </div>
  );
};
