import React, { useState } from "react";
import "./styles.scss";

const OTPInput = ({ len, onTextChanges, status, isOldUser }) => {
    const [values, setValues] = useState(new Array(len).fill(""));
    const refs = [];
    const changeValue = (index, value) => {
        values[index] = value;
        setValues(values);
        onTextChanges(values.join(""));
    };
    const handleKeyPress =
        (index) =>
        ({ key, target: { value } }) => {
            if (key === "Backspace") {
                changeValue(index, "");
                if (index) refs[index - 1].focus();
            } else if (value && index < len - 1) {
                handleChange(index)({ target: { value } });
            }
        };
    const handleChange =
        (index) =>
        ({ target: { value } }) => {
            if (value && index < len) {
                changeValue(index, value);
                refs[index + 1]?.focus();
            }
        };
    const handleFocus = (index) => () => {
        if (index && !values[index - 1] && index < len) {
            refs[index - 1].focus();
        } else if (values[index + 1] && index < len - 1) {
            refs[index + 1]?.focus();
        }
    };
    const inputFields = () => {
        const fields = [];
        for (let i = 0; i < len; i++) {
            fields.push(
                <input
                    key={i}
                    onFocus={handleFocus(i)}
                    name={i.toString()}
                    ref={(e) => {
                        refs[i] = e;
                    }}
                    maxLength={1}
                    value={i+1}
                    onChange={handleChange(i)}
                    onKeyUp={handleKeyPress(i)}
                    disabled={isOldUser}
                />
            );
        }
        return fields;
    };
    return (
        <div id="otp-input" className={status}>
            {inputFields()}
        </div>
    );
};

OTPInput.defaultProps = {
    len: 4,
    onTextChanges: (text) => {},
};

export default OTPInput;
