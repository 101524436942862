import React, { useState } from "react";
import {
  Container,
  BookingContainer,
  LineBreak,
  PickUpDropOffContainer,
  PickUpDropOffWrap,
  Connector,
  PickUpDropOffTextWrap,
  TotalFeedWrap,
  DateTimeWrap,
  DateWrap,
  TimeWrap,
  ShareWrap,
  Button,
  ImageWrap,
  Image,
  ProfileWrap,
  NameTelWrap,
  IconPhoneWrap,
  PhoneIcon,
  PickUpIcon,
  DropOffIcon,
  PickUpText,
  PickUpLocation,
  DropOffText,
  DropOffLocation,
  TotalFee,
  Fee,
  DateIcon,
  DateText,
  TimeIcon,
  TimeText,
  ShareText,
  SocialIconWrap,
  FaceBookIcon,
  WhatsAppIcon,
  MailIcon,
  ButtonWrap,
  NameWrap,
} from "./style";
import phoneIcon from "../../icons/phone.png";
import dropOffIcon from "../../../src/icons/dropoff.png";
import pickUpIcon from "../../../src/icons/pickup.png";
import dateColoredIcon from "../../../src/icons/calender-green-icon.png";
import timeColoredIcon from "../../../src/icons/time-green-icon.png";
import facebookIcon from "../../../src/icons/facebook-icon.png";
import whatsappIcon from "../../../src/icons/whatsapp-icon.png";
import mailIcon from "../../../src/icons/mail.png";
import avatarImage from "../../../src/icons/avatar.png";
import { cancelRideAPI } from "services/api-services/ride";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "antd";

export const ProfileBookPage = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);

  const DateSection = React.forwardRef(({ value, onClick }, ref) => (
    <DateWrap onClick={onClick} ref={ref}>
      <DateIcon src={dateColoredIcon} alt="" />
      <DateText>{value}</DateText>
    </DateWrap>
  ));

  const TimeSection = React.forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} style={{ display: "flex" }}>
      <TimeIcon src={timeColoredIcon} alt="" />
      <TimeText>{value}</TimeText>
    </div>
  ));

  const cancelRide = async () => {
    const result = await cancelRideAPI(props.rideId, {
      phone_no: props.customerPhoneNo,
      status: "CANCELLED",
    });
    return result;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    cancelRide();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function truncate(str) {
    return str?.length > 30 ? str.substring(0, 27) + "..." : str;
  }

  return (
    <div>
      <Container>
        <BookingContainer>
          <>
            <ProfileWrap>
              <ImageWrap>
                {props.driverImage === null ? (
                  <Image src={avatarImage} alt="" />
                ) : (
                  <Image src={props.driverImage} alt="" />
                )}
              </ImageWrap>

              <NameTelWrap>
                <NameWrap>
                  {props.firstName} {props.lastName}
                </NameWrap>
                <IconPhoneWrap>
                  <>
                    <PhoneIcon src={phoneIcon} alt="" />
                    {props.phone}
                  </>
                </IconPhoneWrap>
              </NameTelWrap>
            </ProfileWrap>
          </>

          <div>
            <LineBreak />
          </div>

          <PickUpDropOffContainer>
            <PickUpDropOffWrap>
              <>
                <PickUpIcon src={pickUpIcon} alt="" />
              </>
              <>
                <Connector />
              </>
              <>
                <DropOffIcon src={dropOffIcon} alt="" />
              </>
            </PickUpDropOffWrap>

            <PickUpDropOffTextWrap>
              <PickUpText>Pick up location</PickUpText>
              <PickUpLocation>{truncate(props.pickup)}</PickUpLocation>
              <DropOffText>Drop off location</DropOffText>
              <DropOffLocation>{truncate(props.dropoff)}</DropOffLocation>
            </PickUpDropOffTextWrap>
          </PickUpDropOffContainer>
          <>
            <TotalFeedWrap>
              <TotalFee>Total Fare</TotalFee>
              <Fee>${props.fare}</Fee>
            </TotalFeedWrap>
          </>

          <DateTimeWrap>
            <DateWrap>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<DateSection />}
                popperPlacement="bottom"
                showPopperArrow={false}
                dateFormat="d MMMM"
              />
            </DateWrap>

            <TimeWrap>
              <>
                <DatePicker
                  selected={time}
                  onChange={(date) => setTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  showPopperArrow={false}
                  customInput={<TimeSection />}
                />
              </>
            </TimeWrap>
          </DateTimeWrap>

          <ShareWrap>
            <ShareText>Share tracking link</ShareText>

            <SocialIconWrap>
              <FaceBookIcon src={facebookIcon} alt="" />
              <WhatsAppIcon src={whatsappIcon} alt="" />
              <MailIcon src={mailIcon} alt="" />
            </SocialIconWrap>
          </ShareWrap>

          <ButtonWrap>
            <Button onClick={showModal}>Cancel Ride</Button>
          </ButtonWrap>
          <Modal
            // title="Basic Modal"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Are you sure you wish to cancel ride?</p>
          </Modal>
        </BookingContainer>
      </Container>
    </div>
  );
};
