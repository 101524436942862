import React from "react";
import LOGO from "icons/logo.png";
import { Container, ImageContainer, Image } from "./style";

export const Header = () => {
  return (
    <Container>
      <ImageContainer>
        <Image src={LOGO} />
      </ImageContainer>
    </Container>
  );
};
