import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./styles.css";

const MAPTOKEN = process.env.REACT_APP_MAPTOKEN;

const Map = ReactMapboxGl({
  accessToken: MAPTOKEN,
});

export const ResizedMap = ({ children }) => {
  return (
    <Map className="container" style="mapbox://styles/mapbox/streets-v9">
      {children}
    </Map>
  );
};
