import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";

const Spinner = ({ loading, height = "50vh" }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="spinner-style" style={{ height }}>
      <Spin indicator={antIcon} spinning={loading} />
    </div>
  );
};

const SmallSpinner = ({ loading = true }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18 }} color="#76b138" spin />
  );
  return (
    <div id={"small-loader"}>
      <Spin indicator={antIcon} spinning={loading} />
    </div>
  );
};

export default Spinner;
export { SmallSpinner };
