import Request from "../request";

export const resendOTPAPI = (data, lang) => {
  const resp = Request({
    method: "POST",
    api: "api/guest/sendOtp",
    data,
    lang
  });

  return resp;
};

export const applyCouponAPI = (id, lang,  data) => {
  const resp = Request({
    method: "POST",
    api: "api/applyCoupon/" + id,
    data,
    lang
  });

  return resp;
};

export const bookRideAPI = (data, lang) => {
  const resp = Request({
    method: "POST",
    api: "api/guest/ride",
    data,
    lang
  });

  return resp;
};

export const verifyNumberAPI = (data,lang) => {
  const resp = Request({
    method: "POST",
    api: "api/guest/verifyPhone",
    data,
    lang
  });
  return resp;
};

export const cancelRideAPI = (id,lang, data) => {
  const resp = Request({
    method: "PUT",
    api: `api/guest/ride/${id}`,
    data,
    lang
  });

  return resp;
};

export const retryRideAPI = (id,lang, data) => {
  const resp = Request({
    method: "PUT",
    api: `api/guest/ride/retry/${id}`,
    data,
    lang
  });

  return resp;
};