import moment from "moment";

function parseRideData(data, values, id) {
  const {
    phone_no,
    last_name,
    email,
    first_name,
    passengers,
    // scheduled_date,
    // return_date,
    drop_address,
    drop_address_home,
    pickup_address,
    pickup_address_home,
    selectedTaxi,
    totalDistance,
    notes,
    scheduled_date,
    scheduled_time,
    returned_date,
    returned_time,
    langCode,
  } = data || {};

  let schedule_date_data =
    scheduled_date &&
    scheduled_time &&
    scheduled_date + " " + moment(new Date(scheduled_time)).format("hh:mm A");

  let return_date =
    returned_date && returned_time && returned_date + " " + returned_time;

  let return_date_data =
    returned_date &&
    returned_time &&
    returned_date + " " + moment(new Date(returned_time)).format("hh:mm A");

  let localDate = new Date(`${schedule_date_data}`);
  let returnDate = new Date(`${return_date_data}`);
  let isoReturnDateFormat;
  let isoScheduledDateFormat;

  // for schedule date
  if (scheduled_date && scheduled_time) {
    const date = new Date(localDate);
    const utcScheduledDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    );
    const isoScheduledDate = new Date(utcScheduledDate);
    isoScheduledDateFormat = new Date(
      isoScheduledDate.getTime() - isoScheduledDate.getTimezoneOffset() * 60000
    ).toISOString();
  }
  // for return date
  if (returned_date && returned_time) {
    const date = new Date(returnDate);
    const utcReturnDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    );
    const isoReturnDate = new Date(utcReturnDate);
    isoReturnDateFormat = new Date(
      isoReturnDate.getTime() - isoReturnDate.getTimezoneOffset() * 60000
    ).toISOString();
  }

  return {
    origin: {
      lat: pickup_address?.latitude,
      lng: pickup_address?.longitude,
      longitude: pickup_address?.longitude,
      latitude: pickup_address?.latitude,
      address: pickup_address?.name,
      house_no: pickup_address_home,
    },
    destination: {
      lat: drop_address?.latitude,
      lng: drop_address?.longitude,
      longitude: drop_address?.longitude,
      latitude: drop_address?.latitude,
      address: drop_address?.name,
      house_no: drop_address_home,
    },
    ride_time:
      scheduled_date && scheduled_time ? isoScheduledDateFormat : moment(),
    passengers,
    price: values?.discount?.netPayable || selectedTaxi?.netFare,
    taxi_type_id: selectedTaxi?.id,
    is_scheduled:
      scheduled_date && scheduled_time
        ? moment(scheduled_date) < moment().add(30, "minutes")
          ? false
          : true
        : false,
    // is_scheduled: scheduled_date ? true : false,
    return_ride: return_date ? true : false,
    // return_time:(return_date && moment(return_date)) || null,
    return_time: returned_date && returned_time ? isoReturnDateFormat : null,
    distance: totalDistance,
    payment_mode: "Cash",
    coupon_id: values?.discount?.coupon_id || null,
    note: notes,
    guest: {
      first_name,
      last_name,
      email,
      phone_no: "+" + phone_no,
    },
    region_id: id,
    default_lang: langCode,
  };
}

export { parseRideData };
