import { getToken, deleteToken } from "services/local-store";

export const checkLoggedIn = () => {
  let token = getToken();
  if (token) return true;
  else return false;
};

export const logoutHandler = () => {
  deleteToken();
  window.location.href = "/";
};

export const resParser = (res) => {
  if(res && res.status)return res.data.data
  return []
}

export const  textToUpperCase = (text) => {
  let str = text.split(" ");
  for(let i= 0; i< str.length; i++){
      str[i] = str[i].charAt(0).toUpperCase()+str[i].slice(1);
  }
  let str2 = str.join(" ");
  return str2;
}