import React from "react";
import {
  Container,
  ProfileContainer,
  VerticalLine,
  ProfileWrap,
  ImageWrap,
  DriverImage,
  NameContainer,
  NameWrap,
  StarIcon,
  PhoneWrap,
  PhoneIcon,
  CarWrap,
  VerticalLineWrapper,
  SedanIcon,
  ScheduledReturnRideContainer,
  ScheduledRideCard,
  PickUpDropOffContainer,
  PickUpDropOffWrap,
  Connector,
  PickUpDropOffTextWrap,
  PickUpIcon,
  DropOffIcon,
  PickUpText,
  PickUpLocation,
  DropOffText,
  DropOffLocation,
  ReturnRideCard,
  TotalFareWrap,
  TotalFee,
  Fee,
  Button,
  MapButton,
  Rating,
  MapWrap,
  ScheduledReturnRideWrap,
  ScheduledRideCardContainer,
  ScheduledReturnWrap,
  ScheduledReturnText,
  DateText,
  NameText,
  ReturnDateText,
  SubContainer
} from "./styles";

import starIcon from "../../icons/star.png";
import phoneIcon from "../../icons/phone.png";
import dropOffIcon from "../../../src/icons/dropoff.png";
import pickUpIcon from "../../../src/icons/pickup.png";
// import avatarImage from "../../../src/icons/avatar.png";
import avatarImage from "../../../src/icons/user.png";
import { ResizedMap } from "components/BookingForm/components/smaller-map";
import { Header } from "components/BookingForm/components/header";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const DriverDetailPage = () => {
  let navigate = useNavigate();

  const location = useLocation();

  // const navigateProfile = () => {
  //   navigate("/bookProfile", {
  //     state: {
  //       phone: location.state.phone,
  //       firstName: location.state.firstName,
  //       lastName: location.state.lastName,
  //       pickup: location.state.pickup,
  //       dropoff: location.state.dropoff,
  //       fare: location.state.fare,
  //       driverImage: location.state.driverImage,
  //       rideId: location.state.rideId,
  //       customerPhoneNo: location.state.customerPhoneNo,
  //     },
  //   });
  // };

  function truncate(str) {
    return str?.length > 30 ? str.substring(0, 27) + "..." : str;
  }

  return (
    <>
      {/* <Header /> */}
      <Container>
        <SubContainer>
          <ProfileContainer>
            <ProfileWrap>
              <ImageWrap>
                {location.state.driverImage === null ? (
                  <DriverImage src={avatarImage} alt="" />
                ) : (
                  <DriverImage src={location.state.driverImage} alt="" />
                )}
              </ImageWrap>

              <NameContainer>
                <NameWrap>
                  <NameText>
                    {location.state.firstName} {location.state.lastName}
                  </NameText>
                  <StarIcon src={starIcon} alt="" />
                  <Rating>{Math.round(location.state.rating * 10) / 10}</Rating>
                </NameWrap>

                <PhoneWrap>
                  <>
                    <PhoneIcon src={phoneIcon} alt="" />
                  </>
                  <div>
                    {location.state.phone} {location.state.note}
                  </div>
                </PhoneWrap>

                <CarWrap>
                  <div>{location.state.model}</div>

                  <VerticalLineWrapper>
                    <VerticalLine />
                  </VerticalLineWrapper>
                  <div>{location.state.licenseno}</div>
                </CarWrap>
              </NameContainer>
              {/* <div style={{ marginLeft: "40%" }}>
              <SedanIcon src={location.state.taxiImage} alt="" />
              </div> */}
            </ProfileWrap>
          </ProfileContainer>

          <ScheduledReturnRideContainer>
            <ScheduledReturnRideWrap>
              <ScheduledRideCardContainer>
                {location.state.isScheduled ? (
                  <ScheduledRideCard>
                    <ScheduledReturnWrap>
                      <ScheduledReturnText>{location.state.labels?.schedule_ride_text}</ScheduledReturnText>
                      <DateText>
                        {moment(location.state.scheduledTime).format(
                          "DD MMMM hh:mm a"
                        )}
                      </DateText>
                    </ScheduledReturnWrap>
                    <PickUpDropOffContainer>
                      <PickUpDropOffWrap>
                        <>
                          <PickUpIcon src={pickUpIcon} alt="" />
                        </>
                        <>
                          <Connector />
                        </>
                        <>
                          <DropOffIcon src={dropOffIcon} alt="" />
                        </>
                      </PickUpDropOffWrap>
                      <PickUpDropOffTextWrap>
                        <PickUpText>{location.state.labels?.pick_up_location}</PickUpText>
                        <PickUpLocation>{truncate(location.state.pickup)}</PickUpLocation>
                        <DropOffText>{location.state.labels?.drop_off_location}</DropOffText>
                        <DropOffLocation>
                          {truncate(location.state.dropoff)}
                        </DropOffLocation>
                      </PickUpDropOffTextWrap>
                    </PickUpDropOffContainer>
                  </ScheduledRideCard>
                ) : (
                  <ScheduledRideCard>
                    <ScheduledReturnWrap>
                      <ScheduledReturnText>{location.state.labels?.now_ride}</ScheduledReturnText>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DateText>
                        {moment(location.state.scheduledTime).format(
                          "DD MMMM hh:mm a"
                        )}
                      </DateText>
                    </ScheduledReturnWrap>
                    <PickUpDropOffContainer>
                      <PickUpDropOffWrap>
                        <>
                          <PickUpIcon src={pickUpIcon} alt="" />
                        </>
                        <>
                          <Connector />
                        </>
                        <>
                          <DropOffIcon src={dropOffIcon} alt="" />
                        </>
                      </PickUpDropOffWrap>
                      <PickUpDropOffTextWrap>
                        <PickUpText>{location.state.labels?.pick_up_location}</PickUpText>
                        <PickUpLocation>{truncate(location.state.pickup)}</PickUpLocation>
                        <DropOffText>{location.state.labels?.drop_off_location}</DropOffText>
                        <DropOffLocation>
                          {truncate(location.state.dropoff)}
                        </DropOffLocation>
                      </PickUpDropOffTextWrap>
                    </PickUpDropOffContainer>
                  </ScheduledRideCard>
                )}

                {location.state.isReturn ? (
                  <ReturnRideCard>
                    <ScheduledReturnWrap>
                      <ScheduledReturnText>{location.state.labels?.return_ride_text}</ScheduledReturnText>
                      <ReturnDateText>
                        {moment(location.state.returnTime).format(
                          "DD MMMM hh:mm a"
                        )}
                      </ReturnDateText>
                    </ScheduledReturnWrap>
                    <PickUpDropOffContainer>
                      <PickUpDropOffWrap>
                        <>
                          <PickUpIcon src={pickUpIcon} alt="" />
                        </>
                        <>
                          <Connector />
                        </>
                        <>
                          <DropOffIcon src={dropOffIcon} alt="" />
                        </>
                      </PickUpDropOffWrap>
                      <PickUpDropOffTextWrap>
                        <PickUpText>{location.state.labels?.pick_up_location}</PickUpText>
                        <PickUpLocation>
                          {truncate(location.state.returnOrigin)}
                        </PickUpLocation>
                        <DropOffText>{location.state.labels?.drop_off_location}</DropOffText>
                        <DropOffLocation>
                          {truncate(location.state.returnDestination)}
                        </DropOffLocation>
                      </PickUpDropOffTextWrap>
                    </PickUpDropOffContainer>
                  </ReturnRideCard>
                ) : null}
                <>
                  <TotalFareWrap>
                    <TotalFee>{location.state.total_price}</TotalFee>
                    <Fee>{`${location.state.currency ? location.state.currency : "€"} ${location.state.fare}`}</Fee>
                  </TotalFareWrap>
                </>
                <>
                  <Button onClick={() => { location.state.regionid ? navigate(`/region/${location.state.regionid}`) : navigate("/") }}>{location.state?.labels?.back_to_home}</Button>
                </>
              </ScheduledRideCardContainer>

              {/* <MapWrap>
              <ResizedMap>
                <MapButton
                  onClick={navigateProfile}
                  disabled={
                    location.state.rideStatus === "ACCEPTED" ? false : true
                  }
                >
                  Track your driver
                </MapButton>
              </ResizedMap>
            </MapWrap> */}
            </ScheduledReturnRideWrap>
          </ScheduledReturnRideContainer>
        </SubContainer>
      </Container>
    </>
  );
};
