import React from "react";

import "./index.css";

export default (props) => {
    return (
        <div
            className={
                props.fill
                    ? `filled-btn ${props.disable && "disable"}`
                    : "filled-btn outlined-btn"
            }
            onClick={props.onClick}
        >
            {props.title}
        </div>
    );
};
