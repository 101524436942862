function parseAddress(address) {
  const { center, place_name = "" } = address;
  const [longitude = 0, latitude = 0] = center || [];
  return {
    longitude,
    latitude,
    name: place_name,
  };
}

export { parseAddress };
