import React from "react";
import {
  Container,
  PaymentContainer,
  LineBreak,
  PaymentText,
  PaymentWrapper,
  IconWrapper,
  Icon,
  Payment,
  ButtonWrapper,
  ButtonWrapperPayPal,
  ButtonWrapperIdeal,
  ButtonWrapperCard,
  PaymentPimaryWrap ,
  ButtonWrap,
  Button 
} from "./styles";
import cashIcon from "../../icons/cash.png";
import payPalIcon from "../../icons/paypal.png";
import idealIcon from "../../icons/ideal.png";
import masterCardIcon from "../../icons/master-card.png";
import RadioButton from "components/BookingForm/components/radio-buttons";
import { Header } from "components/BookingForm/components/header";

export const PaymentPage = () => {
  const [radioValue, setRadio] = React.useState("cash");

  const handleChange = (event) => {
    const value = event.target.value;
    setRadio(value);
  };
  return (
      <>
      <Header />
    <Container>
      <PaymentContainer>
        <PaymentText>Select Payment Method</PaymentText>
        <PaymentPimaryWrap>
        <PaymentWrapper>
          <IconWrapper>
            <Icon src={cashIcon} alt="" />

            <Payment>Cash</Payment>
          </IconWrapper>

          <ButtonWrapper>
            <RadioButton
              name="radio"
              value="cash"
              checked={radioValue === "cash"}
              onChange={(event) => handleChange(event)}
            />
          </ButtonWrapper>
        </PaymentWrapper>
        <LineBreak />

        <PaymentWrapper>
          <IconWrapper>
            <Icon src={payPalIcon} alt="" />

            <Payment>Paypal</Payment>
          </IconWrapper>

          <ButtonWrapperPayPal>
            <RadioButton
              name="radio"
              value="paypal"
              checked={radioValue === "paypal"}
              onChange={(event) => handleChange(event)}
            />
          </ButtonWrapperPayPal>
        </PaymentWrapper>

        <LineBreak />
        <PaymentWrapper>
          <IconWrapper>
            <Icon src={idealIcon} alt="" />

            <Payment>Ideal</Payment>
          </IconWrapper>

          <ButtonWrapperIdeal>
            <RadioButton
              name="radio"
              value="ideal"
              checked={radioValue === "ideal"}
              onChange={(event) => handleChange(event)}
            />
          </ButtonWrapperIdeal>
        </PaymentWrapper>

        <LineBreak />

        <PaymentWrapper>
          <IconWrapper>
            <Icon src={masterCardIcon} alt="" />

            <Payment>Credit Card</Payment>
          </IconWrapper>

          <ButtonWrapperCard>
            <RadioButton
              name="radio"
              value="card"
              checked={radioValue === "card"}
              onChange={(event) => handleChange(event)}
            />
          </ButtonWrapperCard>
        </PaymentWrapper>
        </PaymentPimaryWrap> 

        <ButtonWrap>
            <Button>Proceed</Button>
          </ButtonWrap>
 

      </PaymentContainer>
    </Container>
    </>
  );
};
