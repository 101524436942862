import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BookingForm from "components/BookingForm";
import { ProfileBook } from "pages/map-page";
import { WaitCard } from "components/BookingForm/components/wait-card";
import { DriverDetailPage } from "pages/driver-details";
import { PaymentPage } from "pages/payment-page";
import { PaymentStatus } from "pages/payment-status";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<BookingForm />} />
                <Route path="/region/:id" element={<BookingForm />} />

                <Route path="/bookProfile" element={<ProfileBook />} />
                <Route path="/waitCard" element={<WaitCard />} />
                <Route path="/detailPage" element={<DriverDetailPage />} />
                <Route path="/payments" element={<PaymentPage />} />
                <Route path="/paymentStatus" element={<PaymentStatus />} />
            </Routes>
        </BrowserRouter>
    );
};
