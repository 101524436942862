import * as React from 'react';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import moment from "moment";

// const Frame = styled.div`
//     width: 300px;
// `;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #76b138;
  color: white;
  font-family: 'Poppins', sans-serif;
`;

const Button = styled.div`
  cursor: pointer;
`;

const CancelButton = styled.div`
    color: #76b138;
    border: 1px solid;
    padding: 6px 25px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-left:3px;
`;

const SaveButton = styled.button`
    background: #76b138;
    border: 1px solid;
    padding: 6px 34px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    margin-left:3px;
    :disabled {
      pointer-event: none;
      opacity: .6;
    }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  height:18rem;
`;

const Day = styled.div`
  width: 14.2%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  ${(props) =>
    props.isToday &&
    css`
      border: 1px solid #eee;
    `}

  ${(props) =>
    props.isSelected &&
    css`
        background-color: #76b138;
        color: white;
    `}
  ${(props) => 
    props.isDisabled &&
    css`
      opacity: .6;
      pointer-event: none;
    `
  
  }

`;

const Footer = styled.div`
  font-size: 18px;
  font-weight: bold;
  // padding-top:10px;
  // padding-bottom:5px;
  display: flex;
  justify-content: space-between;
  color: white;
  // margin-top: 30px;
  font-family: 'Poppins', sans-serif;


`;

const yesterday = new Date(new Date().setDate(new Date().getDate()-1))

const isPast = (d) => 
  yesterday < d


export default function Calendar({ defaultValue, onSave, onCancelRequest }) {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const today = new Date();
  const [date, setDate] = useState(new Date(defaultValue || today));
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  const onSelect = (date) => {
    if (isPast(date)) {
      setDate(date);
    }
  };

  const convertDate = moment(date).format("YYYY/MM/DD")

  return (
    <>
      <Header>
        <Button onClick={() => setDate(new Date(year, month - 1, day))}><LeftOutlined /></Button>
        <div>
          {MONTHS[month]} {year}
        </div>
        <Button onClick={() => setDate(new Date(year, month + 1, day))}><RightOutlined /></Button>
      </Header>
      <Body>
        {DAYS_OF_THE_WEEK.map((d) => (
          <Day key={d}>
            <strong>{d}</strong>
          </Day>
        ))}
        {Array(days[month] + (startDay - 1))
          .fill(null)
          .map((_, index) => {
            const d = index - (startDay - 2);
            const date = new Date(year, month, d)
            return (
              <Day
                key={index}
                isToday={d === today.getDate()}
                isSelected={d === day}
                onClick={() => onSelect(date)}
                isDisabled={!isPast(date)}
              >
                {d > 0 ? d : ''}
              </Day>
            );
          })}
      </Body>
      <Footer>
        <CancelButton onClick={onCancelRequest}>Cancel</CancelButton>
        <SaveButton onClick={() => onSave(convertDate) } disabled={!isPast(date)? true : false} >Save</SaveButton>
      </Footer>
    </>
  );
}
