import Request from "../request";

export const getRegionAPI = () => {
  const resp = Request({
    method: "GET",
    api: "regions",
    token: true,
  });

  return resp;
};

export const addRegionAPI = (data) => {
  const resp = Request({
    method: "POST",
    api: "regions",
    data,
    token: true,
  });

  return resp;
};

export const getRegionByIdAPI = (id) => {
  const resp = Request({
    method: "GET",
    api: "api/regions/" + id,
    token: true,
  });

  return resp;
};

export const updateRegionByIdAPI = (id, data) => {
  const resp = Request({
    method: "PUT",
    data,
    api: "regions/" + id,
    token: true,
  });

  return resp;
};

export const deleteRegionByIdAPI = (id) => {
  const resp = Request({
    method: "DELETE",
    api: "regions/" + id,
    token: true,
  });

  return resp;
};

export const getTaxiTypesAPI = (data, id, lang) => {
  const resp = Request({
    method: "POST",
    api: "api/taxi/calculateFares/" + id,
    data,
    lang,
    token: true,
  });
  return resp;
  //   return  {
  //     data: {
  //     status: true,
  //     basePrice: 100,
  //     tax: 9,
  //     taxies : [{
  //         id: 1,
  //         name: "Mini",
  //         image: "https://cdn-icons-png.flaticon.com/512/60/60738.png",
  //         description: "iuyrekujewjhgjh asjkdhzJHtDAUAYGYGD....",
  //         meterFare: 102,
  //         tax: 9.18,
  //         netFare: 111.18,
  //         seats: 4
  //     },{
  //       id: 2,
  //       name: "Luxury",
  //       image: "https://cdn-icons-png.flaticon.com/512/60/60738.png",
  //       description: "iuyrekujewjhgjh asjkdhzJHtDAUAYGYGD....",
  //       meterFare: 102,
  //       tax: 9.18,
  //       netFare: 111.18,
  //       seats: 4
  //   },{
  //     id: 3,
  //     name: "Sedan",
  //     image: "https://cdn-icons-png.flaticon.com/512/60/60738.png",
  //     description: "iuyrekujewjhgjh asjkdhzJHtDAUAYGYGD....",
  //     meterFare: 102,
  //     tax: 9.18,
  //     netFare: 111.18,
  //     seats: 4
  // }]
  // }}
  // const resp = Request({
  //   method: "GET",
  //   api: "taxiTypes",
  //   token: true,
  // });

  // return resp;
};
