import React, { useState, useCallback, useEffect } from "react"
import { Button } from "antd"
import Input from "../input"
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import userIcon from "../../../../icons/user-gray.png";

export default ({ maximum = 8 ,onChange,label}) => {

  const [value, setValue] = useState(1)  
  useEffect(()=>{
    onChange(1)
  },[])

  const increment = useCallback(() => {
    if(value < maximum)
   { setValue(value + 1)
    onChange(value+1)
  }
  },[value])

  const decrement = useCallback(() => {
    if(value > 1){
      setValue(value - 1)
      onChange(value-1);
    }
  },[value])


  return (
  <Input
    value={value}
    prefix={<img src={userIcon} style={{width:12,height:13, marginRight:3}} alt=""/>}
    label={label.no_of_passengers}
    readOnly={true}
    suffix={
        <>
            <Button style={{ background: "#eee",height:"25px",marginTop:8,marginBottom:2}} type={"text"}  icon ={ <MinusOutlined style={{ color: "#989797" }} /> } onClick={decrement} />
            <div style={{marginRight:"10px"}} />
            <Button style={{ background: "#eee",height:"25px",marginTop:8,marginBottom:2}} type={"text"} icon ={ <PlusOutlined style={{ color: "#76b138"}}/> } onClick={increment} />
        </>
    }
  />)
}
