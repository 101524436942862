import React, { useEffect } from "react";
import Input from "../components/input";
import "./index.scss";
import { Row, Col, Button, message } from "antd";
import { FieldWrapper } from "../components/fieldWrapper";
import * as FA from "react-icons/fa";
import OTPInput from "components/otp-input-box";
import CustomButton from "components/UI/button";
import { useDetails } from "../utils";
import { validateValues } from "../UserInfo/utils";
import { SmallSpinner } from "../components/spinner";
import {
    applyCouponAPI,
    resendOTPAPI,
    verifyNumberAPI,
    bookRideAPI,
} from "services/api-services/ride";
import { parseRideData } from "./utils";
import userIcon from "../../../icons/user-gray.png";
import taxiIcon from "../../../icons/taxi.png";
import locationIcon from "../../../icons/location-pin.png";
import homeIcon from "../../../icons/home-gray.png";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import SocketSync from "../../../socket";

let socketInstance = SocketSync.getInstance();

const SOCKETURL = process.env.REACT_APP_SOCKETURL;

const Field = ({ lablel, value, prefix, extra, handleEllipse }) => (
    <FieldWrapper>
        <div className="review-field">
            <label className={handleEllipse ? "text" : ""}>{lablel}</label>
            <div className={handleEllipse ? "text" : ""}>
                <span>
                    {prefix}
                    {value}
                </span>
                <span className="mr-25">{extra}</span>
            </div>
        </div>
    </FieldWrapper>
);

export default ({
    details = {},
    setPayload,
    labels,
    regionId,
    isOldUser,
    isUserFetching,
}) => {
    let socket;
    const { values, errors, setFieldValue, handleError } = useDetails({
        verified: true,
    });
    const [disable, setdisable] = React.useState(true);
    let navigate = useNavigate();
    const { id } = useParams();
    
    useEffect(() => {
        if (isOldUser) {
            setFieldValue({
                verified: true,
            });
            setdisable(false);
        } else {
            setFieldValue({
                verified: false,
            });
            setdisable(true);
        }
    }, [isOldUser]);
    
    useEffect( async () => {
        if (details.phone_no) {
            await handleResendOTP();
        }
    }, [isOldUser, details.phone_no]);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    
    const handleResendOTP = async () => {
        setFieldValue({
            sendLoading: true,
        });
        setFieldValue({
            resendLoading: true,
        });
        const resp = await resendOTPAPI({ phone_no: "+" + details.phone_no }, details?.langCode);
        const { status, msg, data } = resp;
        const { msg: sentMsg, isOldUser = false } = data || {};
        if (isOldUser) {
            setFieldValue({
                verified: true,
            });
            setdisable(false);
        } else {
            setFieldValue({
                verified: false,
            });
            setdisable(true);
            if (status) {
                message.success(sentMsg || msg || "OTP sent");
            } else {
                message.error(msg || "something went wrong");
            }
            await delay(300);
            await handleOTP("1234", 0); //otp is always 1234
        }
        setFieldValue(
            {
                resendLoading: false,
            },
            { verify_number: "" }
        );
        setFieldValue({
            sendLoading: false,
        });
    };

    const makeConnectionToSocket = (rideId, userID) => {
        try {
             socket = io(
                `${SOCKETURL}?rideId=${rideId}&userId=${userID}&user=customer`
            );
            
            socket.on("connect", () => {
                socketInstance.setActiveSocket(socket);
            });

            socket.on("rideStatus", (arg) => {
                if (arg.rideDetail?.status === "ACCEPTED") {
                    navigate("/detailPage", {
                        state: {
                            driverImage: arg?.rideDetail?.driver?.image,
                            phone: arg?.rideDetail?.driver?.phone_no,
                            firstName: arg?.rideDetail?.driver?.first_name,
                            lastName: arg?.rideDetail?.driver?.last_name,
                            model: arg?.rideDetail?.driver?.taxi?.model,
                            taxiImage: arg?.rideDetail?.driver?.taxi?.image,
                            taxiType: arg?.rideDetail?.taxiType?.name,
                            scheduledTime: arg?.rideDetail?.ride_time,
                            pickup: arg?.rideDetail?.origin,
                            dropoff: arg?.rideDetail?.destination,
                            fare: arg?.rideDetail?.price,
                            returnTime: arg?.rideDetail?.return_ride?.ride_time,
                            rating: arg?.rideDetail?.driver?.rating,
                            returnOrigin: arg?.rideDetail?.destination,
                            currency:details?.currency,
                            // returnOrigin:arg?.rideDetail?.returnRide?.origin,
                            // returnDestination:arg?.rideDetail?.returnRide?.destination,
                            returnDestination: arg?.rideDetail?.origin,
                            rideId: arg?.rideDetail?.id,
                            customerPhoneNo:arg?.rideDetail?.customer?.phone_no,
                            isReturn: arg?.rideDetail?.return_ride,
                            isScheduled: arg?.rideDetail?.is_scheduled,
                            rideStatus: arg?.rideDetail?.status,
                            licenseno: arg?.rideDetail?.driver?.license,
                            regionid: arg?.rideDetail?.region_id,
                            labels: labels
                        },
                    });
                }
            });

            socket.on("driver_location_update", (arg) => {
                const { latitude, longitude } = arg.rideDetail?.driver || {};
            });

            socket.on("disconnect", () => {
                socketInstance.setActiveSocket(null);
            });
        } catch (error) {
            alert("Disconnected. Check internet or server");
        }
    };

    const handleBookRide = async () => {
        if (values?.verified) {
            setdisable(true);
            let parseRidePayload = parseRideData(details,values, id);
            setFieldValue({
                bookLoading: true,
                // verified: false,
            });

            // if (values?.verifiedMsg) {
            const resp = await bookRideAPI(parseRidePayload, details?.langCode);
            const { status, error, msg, customerId, data } = resp;
            if (status) {
                message.success(msg || "Ride Booked Successfully!");
                makeConnectionToSocket(data, customerId);
                navigate("/waitCard", {
                    state: {
                        // details: details,
                        // values: values,
                        id: data,
                        customerId: customerId,
                        regionid: id,
                        isScheduled:parseRidePayload.is_scheduled,
                        phoneNo:parseRidePayload.guest.phone_no,
                        labels:labels,
                        lang:details?.langCode,
                    },
                });
            } else message.success(error || msg);
            setFieldValue({
                bookLoading: false,
                // verified: true,
            });
            setdisable(false);
        }
    };
    const handleOTP = async (otp, tries) => {
        if (otp && otp.length === 4 && tries<4) {
            setFieldValue(
                {
                    resendLoading: true,
                },
                { verify_number: "" }
            );
            const resp = await verifyNumberAPI({
                phone_no: "+" + details.phone_no,
                otp,
            }, details?.langCode);
            const { status, data } = resp;
            const { isVerified = false, msg } = data || {};
            if (!isVerified) {
                await delay(600);
                await handleOTP("1234", tries+1);
            } else {
                setFieldValue(
                    {
                        verified: isVerified,
                        resendLoading: false,
                        verifiedMsg:
                            (status && "Successfully Applied For The OTP") || "",
                    },
                    {
                        verify_number:
                            (!status && "The OTP you entered is invalid") || "",
                    }
                );
                setdisable(false);
            }
        }
    };
    const onBack = () => {
        setPayload({}, 3, false);
    };
    const hanldeValue =
        (name) =>
        ({ target: { value } }) => {
            setFieldValue(
                {
                    [name]: value,
                },
                { [name]: "" }
            );
        };
    const onApplyCoupon = async () => {
        const { coupon_code } = values;
        const { isError, ...rest } = validateValues(values, {
            coupon_code: { name: "Coupon code" },
        });
        if (!isError) {
            setFieldValue({
                applyLoading: true,
            });
            const resp = await applyCouponAPI(regionId, details?.langCode, {
                amount: details.selectedTaxi?.netFare,
                promocode: coupon_code,
                phone_no: "+" + details.phone_no
            });
            const { msg, data, status } = resp;

            setFieldValue(
                {
                    discount: data || {},
                    appliedMsg: (status && msg) || "",
                    applyLoading: false,
                },
                {
                    coupon_code: !status
                        ? msg || "An Invalid Code Was Entered. Please Try Again"
                        : "",
                }
            );
        } else {
            handleError(rest);
        }
    };

    return (
        <div className="row-margin">
            <div id="review">
                <Row>
                    <Col lg={18} sm={18} xs={15}>
                        <Field
                            lablel={labels.pick_up_location}
                            value={details.pickup_address?.name || ""}
                            prefix={
                                <img
                                    src={locationIcon}
                                    style={{ width: 10, height: 15 }}
                                    alt=""
                                />
                            }
                            handleEllipse={true}
                        />
                    </Col>
                    <Col lg={6} sm={6} xs={9}>
                        <Field
                            lablel={labels.house_no}
                            value={details.pickup_address_home || ""}
                            prefix={
                                <img
                                    src={homeIcon}
                                    style={{ width: 12, height: 13 }}
                                    alt=""
                                />
                            }
                            handleEllipse={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={18} sm={18} xs={15}>
                        <Field
                            lablel={labels.drop_off_location}
                            value={details.drop_address?.name || ""}
                            prefix={
                                <img
                                    src={locationIcon}
                                    style={{ width: 10, height: 15 }}
                                    alt=""
                                />
                            }
                            handleEllipse={true}
                        />
                    </Col>
                    <Col lg={6} sm={6} xs={9}>
                        <Field
                            lablel={labels.house_no}
                            value={details.drop_address_home || ""}
                            prefix={
                                <img
                                    src={homeIcon}
                                    style={{ width: 12, height: 13 }}
                                    alt=""
                                />
                            }
                            handleEllipse={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} sm={24} xs={24}>
                        <Field
                            lablel={labels.taxi}
                            value={details.selectedTaxi?.name || ""}
                            prefix={<img src={taxiIcon} width="18" alt="" />}
                            extra={
                                <b>
                                    {labels.currency}
                                    {details.selectedTaxi?.netFare || 0}
                                </b>
                            }
                            handleEllipse={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={10} sm={10} xs={24}>
                        <Field
                            lablel={labels.no_of_passengers}
                            value={details.passengers || ""}
                            prefix={
                                <img
                                    src={userIcon}
                                    style={{ width: 12, height: 13 }}
                                    alt=""
                                />
                            }
                            handleEllipse={true}
                        />
                    </Col>
                    <Col lg={14} sm={14} xs={24} style={{ marginTop: 28 }}>
                        <Input
                            className={`${
                                (values?.appliedMsg && "success-input") ||
                                (errors["coupon_code"] && "failed-input")
                            }`}
                            prefix={<FA.FaTag />}
                            label={labels.enter_coupon_code}
                            value={values["coupon_code"]}
                            onChange={hanldeValue("coupon_code")}
                            suffix={
                                <>
                                    {values?.applyLoading ? (
                                        <SmallSpinner />
                                    ) : (
                                        <Button
                                            className={
                                                values?.appliedMsg ||
                                                values?.coupon_code ||
                                                errors["coupon_code"]
                                                    ? "apply-green"
                                                    : "apply-btn"
                                            }
                                            onClick={onApplyCoupon}
                                            type="text"
                                        >
                                            {labels.button_apply}
                                        </Button>
                                    )}
                                </>
                            }
                            handleEllipse={true}
                        />
                        {values?.appliedMsg && (
                            <div className="successText">
                                {values?.appliedMsg}
                            </div>
                        )}
                        {errors && errors["coupon_code"] && (
                            <div className="errorText">
                                {errors["coupon_code"]}
                            </div>
                        )}
                    </Col>
                </Row>
                {isUserFetching ? (
                    <div className="spin-root">
                        <SmallSpinner />
                    </div>
                ) : (
                    !isOldUser && (
                        <Row id={"verify-number"}>
                            <Col lg={10} sm={10} xs={24}>
                                <div className="v-txt">
                                    <b>Enter OTP to verify your phone number</b>
                                </div>
                            </Col>
                            <Col lg={14} sm={14} xs={24}>
                                <div className="v-input">
                                    <OTPInput
                                        status={
                                            (values?.verifiedMsg &&
                                                "success") ||
                                            (errors["verify_number"] &&
                                                "failed")
                                        }
                                        onTextChanges={handleOTP}
                                        isOldUser={isOldUser}
                                    />
                                    <>
                                        {values?.verifiedMsg ? (
                                            <div className="verify-container">
                                                <div className="successText">
                                                    {values?.verifiedMsg}
                                                </div>
                                                <div></div>
                                            </div>
                                        ) : (
                                            <>
                                                {values?.resendLoading ? (
                                                    <SmallSpinner />
                                                ) : (
                                                    <div className="verify-container">
                                                        <div className="errorText">
                                                            {(errors &&
                                                                errors[
                                                                    "verify_number"
                                                                ]) ||
                                                                ""}
                                                        </div>
                                                        <div
                                                            className="resent-otp"
                                                            onClick={
                                                                handleResendOTP
                                                            }
                                                            style={
                                                                isOldUser
                                                                    ? {
                                                                          pointerEvents:
                                                                              "none",
                                                                          opacity: 0.7,
                                                                      }
                                                                    : {}
                                                            }
                                                        >
                                                            Resend OTP
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                </div>
                            </Col>
                        </Row>
                    )
                )}

                <Row className="price-slip" justify="space-between">
                    <span>{labels.total_price}</span>
                    <span>
                        <b>
                            <span
                                className={
                                    values?.discount?.netPayable
                                        ? "strike-through"
                                        : ""
                                }
                            >
                                {labels.currency}
                                {details.selectedTaxi?.netFare}
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                {values?.discount?.netPayable && (
                                    <span>{labels.currency}</span>
                                )}
                                {values?.discount?.netPayable}
                            </span>
                        </b>
                    </span>
                </Row>
            </div>
            <div className="steps-action">
                <CustomButton
                    title={labels.button_back || "BACK"}
                    fill={false}
                    onClick={onBack}
                />
                <div className="m-r-17">
                    <CustomButton
                        disable={!values?.verified || disable || isUserFetching}
                        title={
                            values?.bookLoading ? (
                                <SmallSpinner />
                            ) : (
                                labels.button_save || "Proceed"
                            )
                        }
                        fill={true}
                        onClick={handleBookRide}
                    />
                </div>
            </div>
        </div>
    );
};
