import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const PaymentContainer = styled.div`
  display: flex;
  background-color: white;
  margin: 30px;
  border-radius: 12px;
  elevation: 5;
  min-height: 59%;
  padding: 20px;
  box-shadow: 0px 5px 14px #00000029;
  flex-direction: column;
  flex: 0 1 28em;
`;
const LineBreak = styled.div`
  flex-wrap: nowrap;
  width: 95%;
  border-top: 1px solid #00000029;
`;

const PaymentText = styled.div`
  display: flex;
  text-align: left;
  margin-left: 8px;
  font-weight: bold;
  font-size: 20px;
`;

const PaymentPimaryWrap = styled.div`
  margin-top: 10px;
`;

const PaymentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Icon = styled.img`
  height: 67px;
  width: 67px;
`;

const Payment = styled.div`
  margin-top: 23px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 180px;
`;

const ButtonWrapperPayPal = styled.div`
  display: flex;
  margin-left: 170px;
`;
const ButtonWrapperCard = styled.div`
  display: flex;
  margin-left: 141px;
`;
const ButtonWrapperIdeal = styled.div`
  display: flex;
  margin-left: 182px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const Button = styled.div`
  width: 110px;
  height: 30px;
  background-color: #76b138;
  border-radius: 10px;
  cursor: pointer;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  border: 1px solid #76b138;
`;


export {
  Container,
  PaymentContainer,
  LineBreak,
  PaymentText,
  PaymentWrapper,
  IconWrapper,
  Icon,
  Payment,
  ButtonWrapper,
  ButtonWrapperPayPal,
  ButtonWrapperCard,
  ButtonWrapperIdeal,
  PaymentPimaryWrap,
  ButtonWrap,
  Button 
};
