import React, { useState } from "react";
import Input from "../input";
import { Modal } from "antd";
import "./index.css";
import timeIcon from "../../../../icons/time-gray.png";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ThemeProvider, createTheme } from "@mui/material";
import { grey, lightGreen } from "@mui/material/colors";


const theme = createTheme({
  palette: {
    primary: {
      main: lightGreen[600]
    },
    text: {
      primary: grey[500]
    },
  },
  shape: {
    borderRadius: 8,

  },
  typography: {
    fontSize: 11,
    fontFamily: "revert",

  },
});

export default function TimePicker(props) {
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState();

  const getAndPassTime = (date) => {
    const formattedDate = date ? dayjs(date).format("hh:mm:a") : dayjs().format('hh:mm:a');
    setValue(formattedDate);
    props.onChange(date ?? dayjs());
  };

  const selectCurrentTime = () => getAndPassTime(dayjs());


  return (
    <div id="zapp-time-picker">
      <Input
        prefix={<img src={timeIcon} style={{ height: 15, width: 15 }} alt="" />}
        onClick={() => setVisible(true)}
        value={value}
        label={props.labels.time || "Time"}
        readOnly={true}
        {...props}
      />
      <Modal
        centered
        mask
        open={visible}
        closable={false}
        footer={false}
        width={350}
        className="zapp-date-picker"
        contentStyle={{ borderRadius: 20 }}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {visible && (
              <StaticTimePicker
                onChange={getAndPassTime}
                disablePast={props?.disablePast}
                onClose={() => setVisible(false)}
                onClear={() => setValue("")}
                value={value}
                ampm={false}
                slotProps={{
                  actionBar: {
                    actions: ["cancel", "clear", "accept"],
                  },
                }}
              />
            )}
          </LocalizationProvider>
        </ThemeProvider>
      </Modal>
    </div>
  );
};
