import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const StatusContainer = styled.div`
  display: flex;
  background-color: white;
  margin: 30px;
  border-radius: 12px;
  elevation: 5;
  height: 53%;
  padding: 20px;
  box-shadow: 0px 5px 14px #00000029;
  flex-direction: column;
  flex: 0 1 32em;
`;

const CancelIconWrap = styled.div`
  display: flex;
  align-self: flex-end;
`;

const CancelIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const SuccessIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessIcon = styled.img`
  height: 150px;
  width: 150px;
`;

const SuccessText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
`;
const CongratulationsText = styled.div`
  display: flex;
  text-align: center;
`;

const TrackText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const DownloadText = styled.div`
  margin-left: 2px;
  color: #76b138;
`;

const StoreIconWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StoreIcons = styled.img`
  height: 100%;
  width: 60%;
`;

export {
  Container,
  StatusContainer,
  CancelIconWrap,
  CancelIcon,
  SuccessIconWrap,
  SuccessIcon,
  SuccessText,
  CongratulationsText,
  TrackText,
  DownloadText,
  StoreIconWrap,
  StoreIcons
};
