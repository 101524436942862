import styled from "styled-components";

const Container = styled.div`
  display: flex;
  font-family: "Poppins", sans-serif;
  
`;

const BookingContainer = styled.div`
  background-color: white;
  margin-right: 60px;
  border-radius: 12px;
  elevation: 5;
  width: 24%;
  min-width: 22em;
  max-height: 1000px;
  padding: 20px;
  box-shadow: 0px 5px 14px #00000029;
  bottom: 10%;
  right: 0;
  position: absolute;
  z-index: 4;
`;

const LineBreak = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  border-top: 1px solid #c0c0c0;
`;

const PickUpDropOffContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PickUpDropOffWrap = styled.div`
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
`;

const PickUpIconWrap = styled.div``;

const Connector = styled.div`
  height: 30px;
  border-left: 1px solid #76b138;
  margin-left: 5px;
`;

const PickUpDropOffTextWrap = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 8px;
`;

const TotalFeedWrap = styled.div`
  display: flex;
  background-color: #F2F2F2;
  align-items: center;
  border-radius: 5px;
  elevation: 5;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  flex-direction: row;
  border-width: 1px;
`;

const DateTimeWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const DateWrap = styled.div`
  display: flex;
  background-color: #F2F2F2;
  align-items: center;
  border-radius: 5px;
  elevation: 5;
  width: 60%;
  border-width: 1px;
  flex-direction: row;
  cursor: pointer;
`;

const TimeWrap = styled.div`
  display: flex;
  background-color: #F2F2F2;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  elevation: 5;
  width: 60%;
  margin-left: 7px;
  border-width: 1 px;
  flex-direction: row;
  cursor: pointer;
`;

const ShareWrap = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  padding: 9px;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #c0c0c0;
  flex-wrap:wrap;
`;

const Button = styled.div`
  width: 150px;
  height: 35px;
  background-color: #76b138;
  border-radius: 10px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  border: 1px solid #76b138;
`;

const ImageWrap = styled.div`
  margin-bottom: 8px;
  // margin-right:8px;
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 3px;
`;

const ProfileWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameTelWrap = styled.div`
  margin-left: 10px;
`;
const NameWrap = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const IconPhoneWrap = styled.div`
  flex-direction: row;
`;

const PhoneIcon = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;

const PickUpIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
`;

const DropOffIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const PickUpText = styled.div`
  margin-top: 4px;
`;

const PickUpLocation = styled.div`
  margin-top: 4px;
`;

const DropOffText = styled.div`
  margin-top: 6px;
`;

const DropOffLocation = styled.div`
  margin-top: 4px;
`;

const TotalFee = styled.div`
  margin-left: 1px;
`;

const Fee = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;

const DateIcon = styled.img`
  height: 15px;
  width: 15px;
  margin-left: 12px;
  // margin-top: 15px;
`;

const DateText = styled.div`
  margin-left: 9px;
  margin-top: 3px;
`;

const TimeIcon = styled.img`
  height: 15px;
  width: 15px;
  margin-top:12px;
  margin-bottom:12px;
  margin-left:12px;
`;

const TimeText = styled.div`
  margin-left: 6px;
  margin-top: 10px;
`;

const ShareText = styled.div`
  margin-left: 3px;
`;

const SocialIconWrap = styled.div`
  margin-right: 5px;
`;

const FaceBookIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const WhatsAppIcon = styled.img`
  height: 18px;
  width: 18px;
  margin-left: 9px;
`;

const MailIcon = styled.img`
  height: 18px;
  width: 20px;
  margin-left: 9px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export {
  Container,
  BookingContainer,
  LineBreak,
  PickUpDropOffContainer,
  PickUpDropOffWrap,
  PickUpIconWrap,
  Connector,
  PickUpDropOffTextWrap,
  TotalFeedWrap,
  DateTimeWrap,
  DateWrap,
  TimeWrap,
  ShareWrap,
  Button,
  ImageWrap,
  Image,
  ProfileWrap,
  NameTelWrap,
  NameWrap,
  IconPhoneWrap,
  PhoneIcon,
  PickUpIcon,
  DropOffIcon,
  PickUpText,
  PickUpLocation,
  DropOffText,
  DropOffLocation,
  TotalFee,
  Fee,
  DateIcon,
  DateText,
  TimeIcon,
  TimeText,
  ShareText,
  SocialIconWrap,
  FaceBookIcon,
  WhatsAppIcon,
  MailIcon,
  ButtonWrap,
};
