import React, { useState,memo, useEffect } from "react";
import Input from "../components/input";
import { Row, Col, message,} from "antd";
import { FieldWrapper } from "../components/fieldWrapper";
import * as Icon from "react-icons/md";
import "./index.css";
import { useDetails } from "../utils";
import CustomButton from "components/UI/button";
import { getKeys, validateValues } from "./utils";
import userIcon from "../../../icons/user-gray.png";
import docIcon from "../../../icons/notes.png";
import { resendOTPAPI } from "services/api-services/ride";

export default memo(({
    setPayload,
    labels,
    shortCode,
    userHandle,
    userFetching,
    payload
}) => {
    const [emailSuccess, setEmailSuccess] = React.useState(false);
    const [emailMessage, setEmailMessage] = React.useState("");
    const [firstName, setFirstNameMessage] = React.useState("");
    const [firstNameSuccess, setFirstNameSuccess] = React.useState(false);
    const [lastName, setLastNameMessage] = React.useState("");
    const [lastnameSuccess, setLastNameSuccess] = React.useState(false);
    const isEmailValidRegex = /\S+@\S+\.\S+/;
    const isNameValidRegex = /[0-9||\s+]/g;
    const { errors, values, handleValues, handleError, setFieldValue } =
        useDetails();

        
    useEffect(()=>{
      let phone_Number = (values.country_code && values.phone_No) &&  values.country_code+" "+values.phone_No;
      handleValues("phone_no")(phone_Number);
    },[values.phone_No, values.country_code]);
  
    
    const handleSendOTP = async () => {
        setFieldValue({
            sendLoading: true,
        });
        userFetching(true);
        const resp = await resendOTPAPI({ phone_no: "+" + values.phone_no }, payload?.langCode);
        const { status, msg, data } = resp;
        const { msg: sentMsg, isOldUser = false } = data || {};
        if (status) {
            if (sentMsg) message.success(sentMsg);
        } else {
            message.error(msg || "something went wrong");
        }
        userHandle(false);
        userFetching(false);
        setFieldValue(
            {
                sendLoading: false,
            },
            { verify_number: "" }
        );
    };

    const onNext = async () => {
        const { isError, ...rest } = validateValues(values, getKeys(labels));
        if (!isError && emailSuccess) setPayload(values, 2);
        else handleError(rest);
        if (
            values.first_name &&
            values.last_name &&
            values.phone_no !== undefined &&
            values.phone_no.length >= 10
        ) {
            //await handleSendOTP();
            setFieldValue(
              {
                  sendLoading: false,
              },
              { verify_number: "" }
          );
        }
    };
    const onBack = () => {
        setPayload(values, 2, false);
    };

    const handleChange =
        (name) =>
        ({ target: { value } }) => {
            handleValues(name)(value);
        };

    const handleFirstNameChange =
        (name) =>
        ({ target: { value } }) => {
            handleValues(name)(value);
            if (isNameValidRegex.test(value)) {
                setFirstNameSuccess(true);
                setFirstNameMessage(
                    "First Name should not contains Numeric value"
                );
            } else {
                setFirstNameSuccess(false);
            }
        };

    const handleLastNameChange =
        (name) =>
        ({ target: { value } }) => {
            handleValues(name)(value);
            if (isNameValidRegex.test(value)) {
                setLastNameSuccess(true);
                setLastNameMessage(
                    "Last Name should not contains Numeric value"
                );
            } else {
                setLastNameSuccess(false);
            }
        };


    const validateEmail = (value) => {
        if (!isEmailValidRegex.test(value)) {
            setEmailSuccess(false);
            setEmailMessage("Please enter a valid email");
        } else {
            setEmailSuccess(true);
        }
    };

    const handleEmailChange =
        (name) =>
        ({ target: { value } }) => {
            handleValues(name)(value);
            validateEmail(value);
        };

    const handleInputField = (text) => {
        text = text.replace(/[0-9||\s+]/g, "");
        return text;
    };
    const handleCountryCodeChange = (val) => {
        handleValues("country_code")(val.target.value);
    }
    const handlePhoneChange = (val) =>{
        handleValues("phone_No")(val.target.value);
    }       

    return (
      <div>
        <Row>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <Input
                prefix={
                  <img
                    src={userIcon}
                    style={{ height: 12, width: 13 }}
                    alt=""
                  />
                }
                label={labels.customer_firstname || "First Name"}
                value={
                  !values.first_name
                    ? values.first_name
                    : handleInputField(`${values.first_name}`)
                }
                onChange={handleFirstNameChange("first_name")}
              />
              {errors && errors["first_name"] && (
                <div className="errorText">{errors["first_name"]}</div>
              )}
              {firstNameSuccess === true && (
                <span className="errorText" style={{ padding: 0 }}>
                  {firstName}
                </span>
              )}
            </FieldWrapper>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <Input
                prefix={
                  <img
                    src={userIcon}
                    style={{ height: 12, width: 13 }}
                    alt=""
                  />
                }
                label={labels.customer_lastname || "Last Name"}
                value={
                  !values.last_name
                    ? values.last_name
                    : handleInputField(`${values.last_name}`)
                }
                onChange={handleLastNameChange("last_name")}
              />
              {errors && errors["last_name"] && (
                <div className="errorText">{errors["last_name"]}</div>
              )}
              {lastnameSuccess === true && (
                <span className="errorText" style={{ padding: 0 }}>
                  {lastName}
                </span>
              )}
            </FieldWrapper>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <Input
                prefix={<Icon.MdEmail />}
                label={labels.customer_email || "Email"}
                value={values.email}
                onChange={handleEmailChange("email")}
              />
              {errors && errors["email"] && (
                <div className="errorText">{errors["email"]}</div>
              )}
              {emailSuccess === false && (
                <span className="errorText" style={{ padding: 0 }}>
                  {emailMessage}
                </span>
              )}
            </FieldWrapper>
          </Col>
          <Col lg={12} sm={12} xs={24}>
            <FieldWrapper>
              <Row gutter={10}>
                <Col span={8}>
                  <Input
                    label="Code"
                    className="countryCode"
                    value={values.country_code}
                    onChange={handleCountryCodeChange}
                    maxLength="3"
                    prefix={"+"}
                    />
                </Col>
                <Col span={16}>
                  <Input
                    label={labels.customer_phone_no??"Phone Number"}
                    className="phone-no" 
                    value={values.phone_No}
                    onChange={handlePhoneChange}
                    maxLength="13"
                  />
                </Col>
              </Row>
              {errors && errors["phone_no"] && (
                <div className="errorText">{errors["phone_no"]}</div>
              )}

            </FieldWrapper>
          </Col>
        </Row>
        <div>
          <FieldWrapper>
            <div className="notes-container">
              <img
                className="img"
                src={docIcon}
                style={{ height: 12, width: 13 }}
                alt=""
              />
              <textarea
                placeholder={labels.note || "Notes"}
                className="message"
                value={values.notes}
                rows={7}
                // cols={35}
                onChange={handleChange("notes")}
              />
            </div>
            {errors && errors["notes"] && (
              <div className="errorText">{errors["notes"]}</div>
            )}
          </FieldWrapper>
        </div>
        <div className="steps-action">
          <CustomButton
            title={labels.button_back || "BACK"}
            fill={false}
            onClick={onBack}
          />
          <div className="m-r-10">
            <CustomButton
              title={labels.button_next || " Next"}
              fill={true}
              onClick={onNext}
              disable={
                errors["first_name"] ||
                errors["last_name"] ||
                errors["email"] ||
                emailSuccess === false ||
                lastnameSuccess === true ||
                firstNameSuccess === true ||
                errors["phone_no"]
                  ? true
                  : false
              }
            />
          </div>
        </div>
      </div>
    );
});
