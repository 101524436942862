import React from "react";
import { AppRoutes } from "routes";


export default () => {


    return(
        <AppRoutes />
    )
}


// import './App.css'
// import { Steps } from 'antd';
// import { Input } from 'antd';
// import { Button } from 'antd';
// // import { UserOutlined } from '@ant-design/icons';
// import { HomeOutlined } from '@ant-design/icons';
// import { FieldTimeOutlined } from '@ant-design/icons';
// import { UserOutlined } from '@ant-design/icons';
// import { Switch } from 'antd';
// // import Logo1 from '../src/icons/car.png'
// // import Home from '../src/icons/home.png'
// import Loc from '../src/icons/loc.png'
// import Date from '../src/icons/date.png'
// // import Passenger from '../src/icons/pas.png'
// // import Time from '../src/icons/time.png'
// import Return from '../src/icons/img_82834.png'
// import car from "./icons/car.png"

// const { Step } = Steps;

// const Description=({text})=>{
//     return <div className='description-style'>{text}</div>
// }
// function App(){
//     return(
//         <div className='div'>
//         <h1 className='taxi'>Book Your Taxi</h1>
//         <div className='fields'>
//            <Steps current={1} labelPlacement="vertical">
//               <Step  icon={<img className='car' src={car}/>}  description= {<Description text="Select Location"/>}/>
//               <Step  description= {<Description text="Select Taxis"/>}/>
//               <Step   description= {<Description text="Personal Details"/>}/>
//               <Step  description= {<Description text="Review"/>} />
//             </Steps>
//         </div>
//           </div>
//     );
// }
// export default App;



